import { FunctionComponent } from "react";
import './progressBar.css';

type Props = {
    progress?: number
};

const ProgressBar: FunctionComponent<Props> = ({ progress = 0 }) => {

    return (
        <>
            <div className="progressBar">
                <span style={{width: `${progress}%`}}></span>
            </div>
        </>
    )
};

export default ProgressBar;