import { API } from "aws-amplify";
import { GraphQLQuery, graphqlOperation } from "@aws-amplify/api";
import { ListCategoriesQuery, Categories, DeleteCategoriesMutation, CreateCategoriesMutation, UpdateCategoriesInput, UpdateCategoriesMutation, DeleteCategoriesInput } from "../graphql/API";
import { listCategories  } from "../graphql/queries";
import { createCategories, deleteCategories, updateCategories } from "../graphql/mutations";
import Utils from "../utils/Utils";
import { CreateCategoriesInput } from "../graphql/API";

class CategoriesApi extends Utils {

    getCategories = async (): Promise<Categories[]> => {
        const data = await API.graphql<GraphQLQuery<ListCategoriesQuery>>(graphqlOperation(listCategories));
        const items = data.data?.listCategories?.items?.filter(r => r?._deleted !== true) || [];
        return items.filter(r => r !== null) as Categories[];
    };

    removeCategory = async (idCategory: string): Promise<boolean> => {
        const input = {
            id: idCategory
        };
        const data = await API.graphql<GraphQLQuery<DeleteCategoriesMutation>>(graphqlOperation(deleteCategories, { input }));
        const isDeleted = data?.data?.deleteCategories?._deleted || false;
        return isDeleted;
    };

    addCategory = async (category: CreateCategoriesInput): Promise<Categories> => {
        const data = await API.graphql<GraphQLQuery<CreateCategoriesMutation>>({ query: createCategories, variables: { input: category }, authMode: "AMAZON_COGNITO_USER_POOLS" });
        return data.data?.createCategories as Categories;
    };

    updateCategory = async (category: UpdateCategoriesInput): Promise<Categories> => {
        const data = await API.graphql<GraphQLQuery<UpdateCategoriesMutation>>({ query: updateCategories, variables: { input: category }, authMode: "AMAZON_COGNITO_USER_POOLS" });
        return data.data?.updateCategories as Categories;
    };

    deleteCategory = async (category: DeleteCategoriesInput): Promise<Categories> => {
        const data = await API.graphql<GraphQLQuery<DeleteCategoriesMutation>>({ query: deleteCategories, variables: { input: category }, authMode: "AMAZON_COGNITO_USER_POOLS" });
        return data.data?.deleteCategories as Categories;
    };

};

export default CategoriesApi;