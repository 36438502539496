import { FormEventHandler, FunctionComponent, useContext, useEffect } from "react";
import { contactTexts } from "./texts";
import InputText from "../generic/InputText";
import useTranslate from "../translation/useTranslate";
import InputEmail from "../generic/InputEmail";
import TextArea from "../generic/TextArea";
import InputRandom from "../generic/InputRandom";
import SubmitButton from "../generic/SubmitButton";
import { UserContext } from "../layout/Layout";
import useIsMounted from "../customHooks/useIsMounted";
import NotificationApi from "../../api/NotificationsApi";

const notificationsApi = new NotificationApi();

const Contact: FunctionComponent = () => {
    const { getCustomTranslation } = useTranslate();
    const { isComponentMounted } = useIsMounted();
    const { animatedRoutesEnd } = useContext(UserContext);

    const setError = (e: HTMLInputElement, msg: string) => {
        e.classList.add('active');
        e.value = msg;
    };

    const disabledError = (e: HTMLInputElement) => {
        e.classList.remove("active");
        e.value = "";
    };

    const submit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        const target = e.currentTarget;
        const userAlertMsg: HTMLInputElement = target.userAlertMsg;
        const requiredKeys = ["username", "email", "msg", "randomContact"]
        const inputKeys = [...requiredKeys, "number"];
        for (let i = 0; i < inputKeys.length; i++) {
            const inputKey = inputKeys[i];
            if (target[inputKey].classList.contains("invalid")) {
                return;
            }; 
        };
        for (let i = 0; i < requiredKeys.length; i++) {
            const element = requiredKeys[i];
            if (target[element].value.length === 0) {
                setError(userAlertMsg, getCustomTranslation(contactTexts.errorMsg.missField));
                return;
            };
        };
        disabledError(userAlertMsg);
        const submitButton = target.contactSubmitButton;
        submitButton.disabled = true;
        const data = {
            sender: `${target.username.value} ${target.number.value} ${target.email.value}`,
            message: target.msg.value,
        };
        try {
            await notificationsApi.addNotifications(data);
            if (isComponentMounted()) {
                submitButton.disabled = false;
                setError(userAlertMsg, getCustomTranslation(contactTexts.validMsg))
            };
        } catch (error) {
            const errorMsg = notificationsApi.checkAPIError(error);
            if (isComponentMounted()) {
                submitButton.disabled = false;
                setError(userAlertMsg, errorMsg.msg)
            };
        };
    };

    useEffect(() => {
        animatedRoutesEnd();
    }, [animatedRoutesEnd]);

    return (
        <>
            <h1 className="title1">{getCustomTranslation(contactTexts.title)}</h1>
            <span className="largeBorder"></span>
            <form className="form" onSubmit={submit}>
                <InputText inputId="username" inputName="username" inputLabel={getCustomTranslation(contactTexts.inputName)} isRequired={true} inputPLaceholder={getCustomTranslation(contactTexts.inputName) + "*"} />
                <InputEmail inputId="email" inputName="email" inputLabel={getCustomTranslation(contactTexts.inputMail)} isRequired={true} inputPLaceholder={getCustomTranslation(contactTexts.inputMail) + "*"} />
                <InputText inputId="number" inputName="number" inputLabel={getCustomTranslation(contactTexts.inputNumber)} isRequired={false} inputPLaceholder={getCustomTranslation(contactTexts.inputNumber)} />
                <TextArea maxLength={500} inputId="msg" inputName="msg" inputLabel={getCustomTranslation(contactTexts.inputComment)} inputPLaceholder={getCustomTranslation(contactTexts.inputComment) + "*"} isRequired={true} />
                <InputRandom inputId="randomContact" inputName="randomContact" />
                <input className="alert" disabled name="userAlertMsg" />
                <SubmitButton type="submit" classname="submitButton" buttonId="contactSubmitButton" buttonLabel={getCustomTranslation(contactTexts.submit)} />
                <p className="alert active">{getCustomTranslation(contactTexts.nb)}</p>
            </form>
        </>
    );
};

export default Contact;