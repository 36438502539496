import { FunctionComponent, MouseEventHandler, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../layout/Layout";
import TransitionLink from "../transition/TransitionLink";
import { Categories } from "../../graphql/API";
import useIsMounted from "../customHooks/useIsMounted";
import { useAppDispatch, useAppSelector } from "../customHooks/storeHookes";
import CategoriesApi from "../../api/CategoriesApi";
import { ROUTE_NAME } from "../routing/Routing";
import { setCategories } from "../../store/features/categories";

type State = {
    category?: Categories,
    errorMsg?: string,
};

const categoryApi = new CategoriesApi();

const DeleteCategory: FunctionComponent = () => {
    const [state, setState] = useState({} as State);
    const params = useParams();
    const categoryId = params.id;
    const { isComponentMounted } = useIsMounted();
    const { animatedRoutesEnd, animatedRoutesBegin } = useContext(UserContext);
    const categories = useAppSelector(state => state.categories);
    const dispatch = useAppDispatch();

    const deleteCategory: MouseEventHandler<HTMLButtonElement> = async (e) => {
        if (categoryId === undefined) {
            return;
        };
        const target = e.currentTarget;
        target.disabled = true;
        try {
            await categoryApi.deleteCategory({ id: categoryId, _version: state.category?._version });
            if (isComponentMounted()  && categories.categories !== undefined) {
                animatedRoutesBegin(ROUTE_NAME.ADMIN);
                dispatch(setCategories({ categories: categories.categories.filter(r => r.id !== categoryId)}))
            };
        } catch (error) {
            const errorMsg = categoryApi.checkAPIError(error);
            if (isComponentMounted()) {
                target.disabled = false;
                setState({...state, errorMsg: errorMsg.msg});
            };
        };
    };

    useEffect(() => {
        if (typeof categoryId === 'string' && categories.categories !== undefined) {
            const category = categories.categories.find(r => r.id === categoryId);
            setState({ category });
        };
        animatedRoutesEnd();
    }, [categoryId, categories, animatedRoutesEnd]);

    return (
        <>
            <div className="modal">
                <div className="modalBox">
                    <div className="modalBoxHeader">
                        <h1>Attention</h1>
                        <TransitionLink to="/admin" className="pi pi-times"></TransitionLink>
                    </div>
                    <div className="form">
                        <p className="alert active black">Êtes-vous certains de vouloir supprimer la catégorie { state?.category?.title_fr || '' } ?</p>
                        {state.errorMsg && <p className="inputError">{state.errorMsg}</p>}
                        <div className="row end">
                            <button onClick={deleteCategory} className="submitButton black">
                                <i className="pi pi-spin pi-spinner"></i>
                                Confirmer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DeleteCategory;