const locales = {
    logo: {
        en: {
            link: "/en",
        },
        fr: {
            link: "/",
        },
    },
    portfolios: {
        en: {
            label: "Productions",
        },
        fr: {
            label: "Productions",
        },
    },
    about: {
        en: {
            label: "About",
            link: "/en/about",
        },
        fr: {
            label: "A propos",
            link: "/about",
        },
    },
    contact: {
        en: {
            label: "Contact",
            link: "/en/contact",
        },
        fr: {
            label: "Contact",
            link: "/contact",
        },
    },
    language: {
        en: {
            label: "fr",
            link: "/",
        },
        fr: {
            label: "en",
            link: "/en/",
        },
    },
    legalNotice: {
        en: {
            link: "/en/legal_notice",
        },
        fr: {
            link: "/legal_notice",
        },
    },
    contactTitle: {
        en: {
            label: "Contact us",
        },
        fr: {
            label: "Nous contacter",
        },
    },
    noCategory: {
        fr: {
            label: "Aucune catégorie",
        },
        en: {
            label: "No category",
        }
    },
    noVideo: {
        fr: {
            label: "Cette section est en cours de modification par l'administrateur",
        },
        en: {
            label: "This section is currently being amended by the administrator",
        },
    },
};

export default locales;