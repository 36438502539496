import { FunctionComponent } from "react"

const LogoSvg: FunctionComponent = () => {

    return (
        <div className="svgContainer" id="svgContainer">
            <svg className="svg" viewBox="0 0 100 100" overflow="hidden">
                <g>
                    <path d="M 50 5 L 90 40 L 75 70 L 25 70 L 10 40 Z" className="polygone" />
                    <rect x="0" y="43" width="100" height="17" fill="black" stroke="white" strokeWidth="0" />
                    <text x="50" y="57">LA NIAKERIE</text>
                </g>
            </svg>
        </div>
    );
};

export default LogoSvg;