import { FormEventHandler, FunctionComponent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FileUpload from "../generic/FileUpload";
import InputTitle from "../generic/InputTitle";
import Select from "../generic/Select";
import SubmitButton from "../generic/SubmitButton";
import TextArea from "../generic/TextArea";
import { UserContext } from "../layout/Layout";
import { useAppDispatch, useAppSelector } from "../customHooks/storeHookes";
import { ROUTE_NAME } from "../routing/Routing";
import useIsMounted from "../customHooks/useIsMounted";
import VideosApi from "../../api/VideosApi";
import { Storage } from "aws-amplify";
import { addVideos } from "../../store/features/videos";
import InputCheckbox from "../generic/InputCheckbox";
import ProgressBar from "../generic/progressBar/ProgressBar";

type Options = {
    label: string,
    value: string,
};

type State = {
    options?: Options[],
    isError?: boolean,
    errorMsg?: string,
    progress?: number
};

const videoApi = new VideosApi();

const NewVideo: FunctionComponent = () => {
    const [state, setState] = useState({} as State);
    const { isComponentMounted } = useIsMounted();
    const navigate = useNavigate();
    const { animatedRoutesEnd, animatedRoutesBegin } = useContext(UserContext);
    const categories = useAppSelector(state => state.categories);
    const videos = useAppSelector(state => state.videos.videos || []);
    const dispatch = useAppDispatch();

    const submit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        const target = e.currentTarget;
        const inputKeys = ["videoName", "fileUpload"];
        for (let i = 0; i < inputKeys.length; i++) {
            const inputKey = inputKeys[i];
            if (target[inputKey].classList.contains("invalid")) {
                return;
            }; 
        };
        const videoName: string = target.videoName.value;
        const videoNameEn: string = target.videoNameEn.value || videoName;
        const description: string = target.description.value;
        const descriptionEn: string = target.descriptionEn.value;
        const category: string = target.category.value;
        const isHome: boolean = target.isHome.checked;
        const file: File | null = (target.fileUpload.files && target.fileUpload.files[0]) || null;
        const thumbnail: File | null = (target.fileUploadThumbnail.files && target.fileUploadThumbnail.files[0]) || null;
        if (file !== null && thumbnail !== null) {
            try {
                target.newvideoSubmitButton.disabled = true;
                const thumbnailName = `${videoName}_thumbnail`;
                const responseVideo = await fetch(URL.createObjectURL(file));
                const blobVideo = await responseVideo.blob();
                await Storage.put(videoName, blobVideo, {
                    contentType: file.type,
                    level: "public",
                    progressCallback: (progress) => {
                        const avancement = Math.round(( progress.loaded * 100 / progress.total ) / 2);
                        setState(prevState => ({...prevState, progress: avancement}));
                    }
                });
                const videoLink = await Storage.get(videoName, {
                    level: "public"
                });
                const responseThumbnail = await fetch(URL.createObjectURL(thumbnail));
                const blobThumbnail = await responseThumbnail.blob();
                await Storage.put(thumbnailName, blobThumbnail, {
                    contentType: thumbnail.type,
                    level: "public",
                    progressCallback: (progress) => {
                        const avancement = Math.round(( progress.loaded * 100 / progress.total )) + 50;
                        setState(prevState => ({...prevState, progress: avancement}));
                    }
                });
                const thumbnailLink = await Storage.get(thumbnailName, {
                    level: 'public'
                });
                const video = await videoApi.addVideo({
                    title_fr: videoName,
                    title_en: videoNameEn,
                    description_en: descriptionEn,
                    description_fr: description,
                    isHome, categoriesID: category,
                    name: videoName,
                    thumbnail: thumbnailName,
                    videoLink,
                    thumbnailLink
                }, videos);
                dispatch(addVideos({ video: {...video, videoLink, thumbnailLink}}));
                if (isComponentMounted()) {
                    animatedRoutesBegin(ROUTE_NAME.ADMIN);
                };
            } catch (error) {
                const errorMsg = videoApi.checkAPIError(error);
                target.newvideoSubmitButton.disabled = false;
                setState({...state, isError: true, errorMsg: "error : " + errorMsg});
            };
        };
    };

    useEffect(() => {
        const categs = categories.categories || [];
        if (categs === undefined) {
            navigate(ROUTE_NAME.ADMIN);
            return;
        };
        const selectOptions = categs.map(r => ({label: r.title_fr, value: r.id}));
        setState({ options: selectOptions })
        animatedRoutesEnd();
    }, [animatedRoutesEnd, categories, navigate]);

    return (
        <>
            <div>
                <h1 className="title1">Ajouter une nouvelle vidéo</h1>
                <span className="largeBorder"></span>
            </div>
            <form onSubmit={submit} className="form">
                <Select inputId="category" inputLabel="Catégorie" inputName="category" options={state.options || []} isRequired={true} />
                <InputTitle inputLabel="Nom de la vidéo (français)" inputId="videoName" inputName="videoName" isRequired={true} inputPLaceholder="Nom de la vidéo (français)*" />
                <InputTitle inputLabel="Nom de la vidéo (anglais)" inputId="videoNameEn" inputName="videoNameEn" isRequired={false} inputPLaceholder="Nom de la vidéo (anglais)" />
                <TextArea inputLabel="Description de la vidéo (français)" inputId="description" inputName="description" isRequired={false} inputPLaceholder="Description (français)" />
                <TextArea inputLabel="Description de la vidéo (anglais)" inputId="descriptionEn" inputName="descriptionEn" isRequired={false} inputPLaceholder="Description (anglais)" />
                <FileUpload inputId="fileUpload" inputName="fileUpload" isVideo isRequired />
                <FileUpload inputId="fileUploadThumbnail" inputName="fileUploadThumbnail" isRequired />
                <InputCheckbox label="Vidéo d'accueil?" id="isHome" />
                <ProgressBar progress={state.progress} />
                <p className={`alert ${state.isError ? "active" : ""}`}>{state.errorMsg || ""}</p>
                <SubmitButton type="submit" classname="submitButton" buttonId="newvideoSubmitButton" buttonLabel="Valider" />
            </form>
        </>
    );
};

export default NewVideo;