import { FormEventHandler, FunctionComponent, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputTitle from "../generic/InputTitle";
import SubmitButton from "../generic/SubmitButton";
import TextArea from "../generic/TextArea";
import { UserContext } from "../layout/Layout";
import { ROUTE_NAME } from "../routing/Routing";
import { updateVideos } from "../../store/features/videos";
import { useAppDispatch, useAppSelector } from "../customHooks/storeHookes";
import VideosApi from "../../api/VideosApi";
import useIsMounted from "../customHooks/useIsMounted";
import { Videos } from "../../graphql/API";
import InputCheckbox from "../generic/InputCheckbox";

type State = {
    video?: Videos,
    errorMsg?: string,
};

const videoApi = new VideosApi();

const UpdateVideo: FunctionComponent = () => {
    const [state, setState] = useState({} as State);
    const params = useParams();
    const videoId = params.id;
    const { isComponentMounted } = useIsMounted();
    const { animatedRoutesEnd, animatedRoutesBegin } = useContext(UserContext);
    const navigate = useNavigate();
    const videos = useAppSelector(state => state.videos.videos || []);
    const dispatch = useAppDispatch();

    const submit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        if (typeof state.video?.id !== 'string') {
            return;
        }
        const target = e.currentTarget;
        const inputKeys = ["videoName", "videoNameEn", "description", "descriptionEn"];
        for (let i = 0; i < inputKeys.length; i++) {
            const inputKey = inputKeys[i];
            if (target[inputKey].classList.contains("invalid")) {
                return;
            }; 
        };
        const videoName: string = target.videoName.value;
        const videoNameEn: string = target.videoNameEn.value || videoName;
        const description: string = target.description.value;
        const descriptionEn: string = target.descriptionEn.value || description;
        const isHome: boolean = target.isHome.checked;
        if (videoName === state.video?.title_fr && videoNameEn === state.video?.title_en && description === state.video?.description_fr && descriptionEn === state.video?.description_en) {
            setState(prevState => ({...prevState, errorMsg: "Aucune modification n'a été détéctée!"}));
            return;
        };
        const data = {
            id: state.video?.id,
            title_fr: videoName,
            title_en: videoNameEn,
            description_fr: description,
            description_en: descriptionEn,
            isHome,
            _version: state.video?._version
        };
        try {
            target.updateVideoSubmit.disabled = true;
            const updatedVideo = await videoApi.updateVideo(data, videos);
            dispatch(updateVideos({ video: updatedVideo }));
            if (isComponentMounted()) {
                animatedRoutesBegin(ROUTE_NAME.ADMIN);
            };
        } catch (error) {
            const errorMsg = videoApi.checkAPIError(error);
            if (errorMsg && isComponentMounted()) {
                target.updateVideoSubmit.disabled = false;
                setState(prevState => ({...prevState, errorMsg: errorMsg.msg}));
            };
        };
    };

    useEffect(() => {
        const video = videos.find(r => r.id === videoId || '');
        if (typeof video === 'undefined') {
            navigate(ROUTE_NAME.ADMIN);
            return;
        };
        setState({ video })
        animatedRoutesEnd();
    }, [videoId, navigate, animatedRoutesEnd, videos]);

    return (
        <>
            {state.video && <>
                <div>
                    <h1 className="title1">Modifier la vidéo {state.video.title_fr}</h1>
                    <span className="largeBorder"></span>
                </div>
                <form onSubmit={submit} className="form">
                    <InputTitle defaultvalue={state.video.title_fr} inputLabel="Nom de la vidéo (français)" inputId="videoName" inputName="videoName" isRequired inputPLaceholder="Nom de la vidéo (français)*" />
                    <InputTitle defaultvalue={state.video.title_en || ''} inputLabel="Nom de la vidéo (anglais)" inputId="videoNameEn" inputName="videoNameEn" inputPLaceholder="Nom de la vidéo (anglais)" />
                    <TextArea defaultvalue={state.video.description_fr || ''} inputLabel="Description de la vidéo (français)" inputId="description" inputName="description" inputPLaceholder="Description (français)" />
                    <TextArea defaultvalue={state.video.description_en || ''} inputLabel="Description de la vidéo (anglais)" inputId="descriptionEn" inputName="descriptionEn" inputPLaceholder="Description (anglais)" />
                    <InputCheckbox label="Vidéo d'accueil?" id="isHome" />
                    <p className={`alert ${state.errorMsg ? "active" : ""}`}>{state.errorMsg || ""}</p>
                    <SubmitButton buttonId="updateVideoSubmit" buttonLabel="Valider les modifications" type="submit" classname="submitButton" />
                </form>
            </>}
        </>
    );
};

export default UpdateVideo;