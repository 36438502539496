import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import BackRow from "../backRow/BackRow";
import Footer from "../footer/Footer";
import LogoSvg from "../logoSvg/LogoSvg";
import Nav from "../nav/Nav";
import Routing from "../routing/Routing";
import VideosApi from "../../api/VideosApi";
import useIsMounted from "../customHooks/useIsMounted";
import { useAppDispatch } from "../customHooks/storeHookes";
import { setVideos } from "../../store/features/videos";
import { Storage } from "aws-amplify";
import { Videos } from "../../graphql/API";
import CookieConsent from "../cookieConsent/CookieConsent";

type Context = {
    animatedRoutesBegin: (to: string) => void,
    animatedRoutesEnd: () => void,
};

export const UserContext = React.createContext({} as Context);

const videosApi = new VideosApi();

const Layout: FunctionComponent = () => {
    const [state, setState] = useState(false);
    const animatedTransition = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const { isComponentMounted } = useIsMounted();
    const dispatch = useAppDispatch();

    const animatedRoutesBegin = useCallback((to: string) => {
        animatedTransition.current?.classList.add("active");
        setTimeout(() => {
            navigate(to);
        }, 500);
    }, [navigate]);

    const animatedRoutesEnd = useCallback(() => {
        if (animatedTransition.current?.classList.contains("active")) {
            animatedTransition.current?.classList.remove("active");
        };
    }, []);

    const getVideos = useCallback(async () => {
        let videos: Videos[] = [];
        try {
            const videosWithoutFileLink = await videosApi.getVideos();
            for (let i = 0; i < videosWithoutFileLink.length; i++) {
                const videoToPush = {...videosWithoutFileLink[i]};
                const videoLink = await Storage.get(videosWithoutFileLink[i].name, {
                    level: 'public'
                });
                videoToPush.videoLink = videoLink;
                const thumbnailLink = await Storage.get(videosWithoutFileLink[i].thumbnail, {
                    level: 'public'
                });
                videoToPush.thumbnailLink = thumbnailLink;
                videos.push(videoToPush);
            };
        } finally {
            if (isComponentMounted()) {
                dispatch(setVideos({ videos }));
                setState(true);
            };
        };
    }, [dispatch, isComponentMounted]);

    useEffect(() => {
        getVideos();
    }, [getVideos]);

    return (
        <>
            <BackRow side="left" />
            <BackRow side="right" />
            <UserContext.Provider value={{animatedRoutesBegin, animatedRoutesEnd}}>
                <Nav />
                <div className="main">
                    <div className="transition active" ref={animatedTransition}>
                        <LogoSvg />
                    </div>
                    {state && <Routing />}
                </div>
                <Footer />
            </UserContext.Provider>
            <CookieConsent />
        </>
    )
};

export default Layout;