import { FunctionComponent, MouseEventHandler, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../layout/Layout";
import useTranslate from "../translation/useTranslate";
import { ROUTE_NAME } from "../routing/Routing";
import { useAppSelector } from "../customHooks/storeHookes";
import { Videos } from "../../graphql/API";

type State = {
    video?: Videos,
    errorMsg?: string,
};

const VideoPlay: FunctionComponent = () => {
    const [state, setState] = useState({} as State);
    const params = useParams();
    const videoId = params.id;
    const { getCustomTranslation } = useTranslate();
    const { animatedRoutesEnd } = useContext(UserContext);
    const navigate = useNavigate();
    const videos = useAppSelector(state => state.videos.videos || []);
    const isAuth = useAppSelector(state => state.user.isAuth || false);

    const contextMenu: MouseEventHandler<HTMLVideoElement> = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        const video = videos.find(r => r.id === videoId || '');
        if (typeof video === 'undefined') {
            navigate(ROUTE_NAME.HOME);
            return;
        };
        setState({ video })
        animatedRoutesEnd();
    }, [videoId, navigate, animatedRoutesEnd, videos]);

    return (
        <>
            {state.video && <>
                <h1 className="title1">{getCustomTranslation({en: state.video.title_en || '', fr: state.video.title_fr || ''})}</h1>
                <span className="largeBorder"></span>
                <div className="form">
                    <video
                        className="videoPlayer"
                        src={state.video.videoLink || ''}
                        controls
                        onContextMenu={contextMenu}
                        autoPlay
                        controlsList={isAuth ? "" : "nodownload"}
                    />
                    <span className="largeBorder"></span>
                    <p className="paragraph">
                        {getCustomTranslation({en: state.video.description_en || '', fr: state.video.description_fr || ''})}
                    </p>
                </div>
            </>}
        </>
    );
};

export default VideoPlay;