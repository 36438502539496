import { FocusEventHandler, FunctionComponent, useState } from "react";
import useTranslate from "../translation/useTranslate";

type Props = {
    inputId: string,
    inputName: string,
    inputPLaceholder: string,
    inputLabel: string,
    maxLength?: number,
    isRequired?: boolean,
    defaultvalue?: string,
};

type InputError = {
    isError: boolean,
    errorMsg: string,
}

type State = InputError & { length: number, };

const TextArea: FunctionComponent<Props> = ({defaultvalue, inputLabel, inputId, inputName, inputPLaceholder, isRequired = false, maxLength = 300}) => {
    const [state, setState] = useState({} as State);
    const { getCustomTranslation } = useTranslate();

    const errMsg = {
        length: {
            en: `Message cannot be longer than ${maxLength}!`,
            fr: `Le message ne peut pas excéder ${maxLength} caractères!`,
        },
        content: {
            en: "Message must contains only alphanumeric and '_' caracters!",
            fr: "Le message ne peut contenir que des caractères alphanumériques et '_'!",
        },
        require: {
            en: "Message is required",
            fr: "Le message est obligatoire",
        },
    };

    const checkValue = (value: string): InputError => {
        if (value.length > 0) {
            if (value.length > maxLength) {
                return {isError: true, errorMsg: getCustomTranslation(errMsg.length)};
            };
            return {} as InputError;
        };
        if (isRequired) {
            return {isError: true, errorMsg: getCustomTranslation(errMsg.require)};
        };
        return {} as InputError;
    };

    const handleChange: FocusEventHandler<HTMLTextAreaElement> = (e) => {
        const target = e.currentTarget;
        const value = target.value;
        const isInvalid = checkValue(value);
        const length = value.length;
        setState({...isInvalid, length});
    };

    return (
        <>
            <label htmlFor={inputId} className="inputHelp">{inputLabel} :</label>
            <textarea
                id={inputId}
                name={inputName}
                placeholder={inputPLaceholder}
                className={`input textarea ${state.isError ? "invalid" : ""}`}
                required={isRequired || false}
                onChange={handleChange}
                defaultValue={defaultvalue || ""}
            />
            <small className="inputHelp">{state.length || 0}/{maxLength}</small>
            {state.isError && <p className="inputError">{state.errorMsg}</p>} 
        </>
    );
};

export default TextArea;