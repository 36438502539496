import { FunctionComponent, MouseEventHandler, useContext, useEffect, useState } from "react";
import useTranslate from "../translation/useTranslate";
import { useAppSelector } from "../customHooks/storeHookes";
import { Videos } from "../../graphql/API";
import { UserContext } from "../layout/Layout";
import { useLocation } from "react-router-dom";

type State = {
    video?: Videos,
};

const locales = {
    small: {
        en: "Audiovisual production agency",
        fr: "Créateur de contenu audio-visuel",
    },
};

const Home: FunctionComponent = () => {
    const [state, setState] = useState<State>({});
    const { getCustomTranslation } = useTranslate();
    const videos = useAppSelector(state => state.videos.videos || []);
    const { animatedRoutesEnd } = useContext(UserContext);
    const location = useLocation();
    
    const contextMenu: MouseEventHandler<HTMLVideoElement> = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        if (videos !== undefined) {
            setState({ video: videos.find(r => r.isHome) });
        };
        animatedRoutesEnd();
    }, [videos, animatedRoutesEnd, location]);

    return (
        <>
            <div className="homeBox">
                <h1>La niakerie</h1>
                <small>{getCustomTranslation(locales.small)}</small>
            </div>
            <div className="homeVideosItem">
            {state.video !== undefined && <video 
                src={state.video.videoLink || ''}
                disablePictureInPicture
                autoPlay
                muted
                onContextMenu={contextMenu}
                loop
            />}
            </div>
        </>
    );
};

export default Home;