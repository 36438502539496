import { FunctionComponent, useCallback, useEffect, useState } from "react";
import TransitionLink from "../transition/TransitionLink";
import useTranslate from "../translation/useTranslate";
import useIsMounted from "../customHooks/useIsMounted";
import CategoriesApi from "../../api/CategoriesApi";
import { Categories } from "../../graphql/API";
import { useAppDispatch, useAppSelector } from "../customHooks/storeHookes";
import { setCategories } from "../../store/features/categories";

type State = {
    categories: Categories[],
};

const categoriesApi = new CategoriesApi();

const SubCategory: FunctionComponent = () => {
    const { getTranslation, getCustomTranslation } = useTranslate();
    const [state, setState] = useState({ categories: []} as State);
    const { isComponentMounted } = useIsMounted();
    const dispatch = useAppDispatch();
    const categories = useAppSelector(state => state.categories);

    const sortCategories = useCallback((categs: Categories[]): Categories[] => {
        let newCategories = [...categs];
        if (categs.length > 0) {
            const isPublicite = categs.find(r => r.title_fr.toLowerCase() === "publicité");
            if (isPublicite !== undefined) {
                newCategories = [isPublicite];
                for (let i = 0; i < categs.length; i++) {
                    const element = categs[i];
                    if (element.title_fr.toLowerCase() !== "publicité") {
                        newCategories.push(element);
                    };
                };
            };
        };
        return newCategories;
    }, []);

    const getCategories = useCallback(async () => {
        try {
            const data = await categoriesApi.getCategories();
            if (isComponentMounted()) {
                const newData = sortCategories(data);
                setState({ categories: newData });
                dispatch(setCategories({categories: data}));
            };
        } catch (error) {
            categoriesApi.checkAPIError(error);
        };

    }, [isComponentMounted, dispatch, sortCategories]);

    useEffect(() => {
        if (categories.categories === undefined) {
            getCategories();
            return;
        };
        const newData = sortCategories(categories.categories || []);
        setState({ categories:  newData});
    }, [getCategories, categories, sortCategories]);

    return (
        <>
            <div className="relative">
                <TransitionLink to={state.categories === undefined || state.categories.length === 0 ? getCustomTranslation({en: "/en/", fr: "/"}) : `${getCustomTranslation({en: "/en/", fr: "/"})}portfolios/${state.categories[0].id}`} className="navLink">{getTranslation("portfolios").label}</TransitionLink>
                <div className="submenu">
                    {state.categories && state.categories.length > 0 ? 
                        state.categories.map(r => (
                            <TransitionLink
                                key={r.id}
                                to={`${getCustomTranslation({en: "/en/", fr: "/"})}portfolios/${r.id}`}
                                className="submenuItem"
                            >
                                {getCustomTranslation({en: r.title_en || r.title_fr, fr: r.title_fr})}
                            </TransitionLink>
                        ))
                    :
                    <p className="submenuItem">{getTranslation("noCategory").label}</p>}
                </div>
            </div>
        </>
    )
};

export default SubCategory;