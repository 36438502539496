import { FunctionComponent, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../layout/Layout";
import useTranslate from "../translation/useTranslate";
import VideoGallery from "../videos/VideoGallery";
import { useAppSelector } from "../customHooks/storeHookes";
import { Categories } from "../../graphql/API";
import { ROUTE_NAME } from "../routing/Routing";

type State = {
    category?: Categories,
    errorMsg?: string,
};

const Portfolios: FunctionComponent = () => {
    const [state, setState] = useState({} as State);
    const params = useParams();
    const { getCustomTranslation } = useTranslate();
    const categoryId = params.id;
    const { animatedRoutesEnd } = useContext(UserContext);
    const videos = useAppSelector(state => state.videos.videos || []);
    const categories = useAppSelector(state => state.categories.categories || []);
    const navigate = useNavigate();

    useEffect(() => {
        if (categoryId !== undefined) {
            const category = categories.find(r => r.id === categoryId);
            if (category !== undefined) {
                setState({ category });
                animatedRoutesEnd();
                return;
            };
        };
        navigate(ROUTE_NAME.HOME);
    }, [animatedRoutesEnd, categories, videos, categoryId, navigate]);

    return (
        <>
            <h1 className="title1">{getCustomTranslation({en: state.category?.title_en || "", fr: state.category?.title_fr || ""})}</h1>
            <span className="largeBorder"></span>
            <VideoGallery categoryId={categoryId || ""} videos={videos} />
        </>
    );
};

export default Portfolios;