import { ChangeEventHandler, FunctionComponent } from "react";

type Options = {
    label: string,
    value: string,
};

type Props = {
    inputId: string,
    inputName: string,
    isRequired: boolean,
    inputLabel: string,
    options: Options[],
    onchange?: ChangeEventHandler<HTMLSelectElement>,
};

const Select: FunctionComponent<Props> = ({inputId, inputName, isRequired, options, inputLabel, onchange}) => {
    const defaultValue = options.length > 0 ? options[0].value : "";

    return (
        <>
            <label htmlFor={inputId} className="inputHelp">{inputLabel} :</label>
            <select 
                name={inputName}
                id={inputId}
                defaultValue={defaultValue}
                required={isRequired}
                className={`input pointer`}
                onChange={onchange}
            >
                {options.map((r, i) => (
                    <option value={r.value} key={r.value + i}>{r.label}</option>
                ))}
            </select>
        </>
    );
};

export default Select;