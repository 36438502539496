import { FormEventHandler, FunctionComponent, useContext, useEffect } from "react";
import InputEmail from "../generic/InputEmail";
import TransitionLink from "../transition/TransitionLink";
import InputText from "../generic/InputText";
import SubmitButton from "../generic/SubmitButton";
import useIsMounted from "../customHooks/useIsMounted";
import UserApi from "../../api/UserApi";
import { UserContext } from "../layout/Layout";

const userApi = new UserApi();

const ForgotPasswordSubmit: FunctionComponent = () => {
    const { animatedRoutesEnd } = useContext(UserContext);
    const { isComponentMounted } = useIsMounted();

    const isValidForm = (target: EventTarget & HTMLFormElement): boolean => {
        const inputKeys = ["email", "code", "newPassword"];
        for (let i = 0; i < inputKeys.length; i++) {
            const inputKey = inputKeys[i];
            if (target[inputKey].classList.contains("invalid")) {
                return false;
            }; 
        };
        return true;
    };

    const submit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        const target = e.currentTarget;
        const email = target.email.value;
        if (isValidForm(target) && email.length > 0) {
            const userAlertMsg = target.userAlertMsg;
            const submitButton = target.forgotPasswordSubmitButton;
            const code = target.code.value;
            const newPassword = target.newPassword.value;
            userAlertMsg.classList.remove("active");
            submitButton.disabled = true;
            let alertMsg = '';
            try {
                await userApi.forgotPasswordSubmit(email, code, newPassword);
                alertMsg = 'Votre mot de passe a été modifié, vous pouvez vous reconnecter avec votre nouveau mot de passe';
            } catch (error) {
                const errorMsg = userApi.checkAPIError(error);
                alertMsg = errorMsg.msg;
            } finally {
                if (isComponentMounted()) {
                    submitButton.disabled = false;
                    userAlertMsg.value = alertMsg;
                    userAlertMsg.classList.add("active");
                };
            };
        };
    };

    const enabledForm: FormEventHandler<HTMLFormElement> = (e) => {
        const target = e.currentTarget;
        const submitButton = target.forgotPasswordSubmitButton;
        if (submitButton.classList.contains("d-none")) {
            submitButton.classList.remove("d-none");
            submitButton.classList.add("submitButton");
            submitButton.type = "submit";
        };
    };

    useEffect(() => {
        animatedRoutesEnd();
    }, [animatedRoutesEnd]);

    return (
        <>
            <h1 className="title1">Mot de passe oublié</h1>
            <span className="largeBorder"></span>
            <h3>Un email avec un code vous a été envoyé</h3>
            <form className="form" onSubmit={submit} onChange={enabledForm}>
                <InputEmail inputLabel="Veuillez renseigner votre adresse mail" inputId="email" inputName="email" isRequired={true} inputPLaceholder="Email*" />
                <InputText inputLabel="Veuillez renseigner votre code" inputId="code" inputName="code" isRequired={true} inputPLaceholder="Code*" />
                <InputText inputLabel="Veuillez renseigner votre nouveau mot de passe" inputId="newPassword" inputName="newPassword" isRequired={true} inputPLaceholder="Nouveau mot de passe*" />
                <TransitionLink to="/login" className="inputHelp link">Retour à la page de connexion</TransitionLink>
                <input type="text" name="userAlertMsg" className="alert" />
                <SubmitButton buttonId="forgotPasswordSubmitButton" buttonLabel="Valider" classname="d-none" />
            </form>
        </>
    );
};

export default ForgotPasswordSubmit;