import { createSlice } from '@reduxjs/toolkit';
import { Videos } from '../../graphql/API';

type VideosStoreState = {
    videos?: Videos[]
};

const initialState: VideosStoreState = {};

export const videosSlice = createSlice({
    name: 'videos',
    initialState,
    reducers: {
      setVideos: (state, action) => {
        state.videos = action.payload.videos;
      },
      addVideos: (state, action) => {
        const video = action.payload.video;
        const initialVideos = state.videos || [];
        const newVideos = video.isHome ? [...initialVideos.map(r => ({...r, isHome: null})), video] : [...initialVideos, video];
        state.videos = newVideos;
      },
      updateVideos: (state, action) => {
        const video = action.payload.video;
        const initialVideos = state.videos || [];
        state.videos = initialVideos.map(r => r.id === video.id ? { ...video } : {...r, isHome: video.isHome ? null : r.isHome});
      }
    },
})

export const { setVideos, addVideos, updateVideos } = videosSlice.actions;

export default videosSlice.reducer;