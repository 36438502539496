import { useCallback, useEffect, useRef } from "react";

const useIsMounted = () => {
    const isMount = useRef(false);

    const isComponentMounted = useCallback((): boolean => {
        return isMount.current;
    }, []);

    useEffect(() => {
        isMount.current = true;

        return () => {
            isMount.current = false;
        };
    }, []);

    return (
        { isComponentMounted }
    );
};

export default useIsMounted;