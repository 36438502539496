export const contactTexts = {
    title: {
        fr: "Nous contacter",
        en: "Contact us"
    },
    inputName: {
        fr: "Votre nom",
        en: "Your name"
    },
    inputMail: {
        fr: "Votre email",
        en: "Your email"
    },
    inputNumber: {
        fr: "Votre numéro",
        en: "Your number"
    },
    inputComment: {
        fr: "Votre message",
        en: "Your message"
    },
    submit: {
        fr: "Envoyer",
        en: "Send"
    },
    errorMsg: {
        missField: {
            fr: "Veuillez compléter tous les champs",
            en: "Thanks for complete all input"
        },
        wrongCaptcha: {
            fr: "Merci de répondre à la question de sécurité",
            en: "Thanks for answer the security question"
        }
    },
    validMsg: {
        fr: "Votre email a bien été envoyé, merci!",
        en: "Your email has been sent correctly, thanks!"
    },
    nb: {
        fr: "Aucune donnée personnelle n’est conservée par notre site via ce formulaire ou transmis à un tiers. Les données renseignées nous seront envoyées par mail et nous permettront de vous recontacter.",
        en: "We don't stored or send any personal information via this form. Data provided will be sent to us and will permit us to contact you back.",
    }
};