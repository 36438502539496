import { FocusEventHandler, FunctionComponent, useState } from "react";
import validator from "validator";
import useTranslate from "../translation/useTranslate";

type Props = {
    inputId: string,
    inputName: string,
    inputPLaceholder: string,
    isRequired: boolean,
    inputLabel: string,
    defaultvalue?: string,
};

type State = {
    isError: boolean,
    errorMsg: string,
};

const errMsg = {
    content: {
        en: "Email invalid",
        fr: "Email invalide",
    },
    require: {
        en: "Email is required",
        fr: "L'email est obligatoire",
    },
};

const InputEmail: FunctionComponent<Props> = ({inputId, inputName, inputPLaceholder, isRequired, defaultvalue, inputLabel}) => {
    const [state, setState] = useState({} as State);
    const { getCustomTranslation } = useTranslate();

    const checkValue = (value: string): State => {
        if (value.length > 0) {
            if (!validator.isEmail(value)) {
                return {isError: true, errorMsg: getCustomTranslation(errMsg.content)};
            };
            return {} as State;
        };
        if (isRequired) {
            return {isError: true, errorMsg: getCustomTranslation(errMsg.require)};
        };
        return {} as State;
    };

    const blur: FocusEventHandler<HTMLInputElement> = (e) => {
        const target = e.currentTarget;
        const value = target.value;
        const isInvalid = checkValue(value);
        setState(isInvalid);
    };


    return (
        <>
            <label htmlFor={inputId} className="inputHelp">{inputLabel} :</label>
            <input
                type="mail"
                id={inputId}
                name={inputName}
                placeholder={inputPLaceholder}
                className={`input ${state.isError ? "invalid" : ""}`}
                required={isRequired || false}
                onBlur={blur}
                defaultValue={defaultvalue || ""}
            />
            {state.isError && <p className="inputError">{state.errorMsg}</p>}
        </>
    );
};

export default InputEmail;