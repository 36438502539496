import { API, graphqlOperation } from "aws-amplify";
import Utils from "../utils/Utils";
import { GraphQLQuery } from "@aws-amplify/api";
import { CreateVideosInput, CreateVideosMutation, DeleteVideosInput, DeleteVideosMutation, ListVideosQuery, UpdateVideosInput, UpdateVideosMutation, Videos } from "../graphql/API";
import { listVideos } from "../graphql/queries";
import { createVideos, deleteVideos, updateVideos } from "../graphql/mutations";

class VideosApi extends Utils {

    getVideos = async (): Promise<Videos[]> => {
        const videos = await API.graphql<GraphQLQuery<ListVideosQuery>>(graphqlOperation(listVideos));
        const data = videos.data?.listVideos?.items?.filter(r => r?._deleted !== true) || [];
        return data.filter(r => r !== null) as Videos[];
    };

    addVideo = async (video: CreateVideosInput, videos: Videos[]): Promise<Videos> => {
        const newVideo = await API.graphql<GraphQLQuery<CreateVideosMutation>>({ query: createVideos, variables: { input: video }, authMode: "AMAZON_COGNITO_USER_POOLS" });
        if (video.isHome) {
            await this.updateIsHomeVideos(videos);
        };
        return newVideo.data?.createVideos as Videos;
    };

    updateVideo = async (video: UpdateVideosInput, videos: Videos[]): Promise<Videos> => {
        const updatedVideo = await API.graphql<GraphQLQuery<UpdateVideosMutation>>({ query: updateVideos, variables: { input: video }, authMode: "AMAZON_COGNITO_USER_POOLS" });
        if (video.isHome) {
            await this.updateIsHomeVideos(videos);
        };
        return updatedVideo.data?.updateVideos as Videos;
    };

    deleteVideo = async (video: DeleteVideosInput): Promise<Videos> => {
        const deletedVideo = await API.graphql<GraphQLQuery<DeleteVideosMutation>>({ query: deleteVideos, variables: { input: video }, authMode: "AMAZON_COGNITO_USER_POOLS" });
        return deletedVideo.data?.deleteVideos as Videos;
    };

    private updateIsHomeVideos = async (videos: Videos[]): Promise<void> => {
        const isHomeVideo =  videos.filter(r => r.isHome);
        if (isHomeVideo.length > 0) {
            for (let i = 0; i < isHomeVideo.length; i++) {
                const element = isHomeVideo[i];
                await  API.graphql<GraphQLQuery<UpdateVideosMutation>>({ query: updateVideos, variables: { input: { id: element.id, isHome: null, _version: element._version } }, authMode: "AMAZON_COGNITO_USER_POOLS" });
            };
        };
    };

};

export default VideosApi;