import { ChangeEventHandler, FunctionComponent, useContext, useEffect, useState } from "react";
import Select from "../generic/Select";
import { UserContext } from "../layout/Layout";
import TransitionLink from "../transition/TransitionLink";
import VideoGallery from "../videos/VideoGallery";
import { useAppSelector } from "../customHooks/storeHookes";

type Options = {
    label: string,
    value: string,
};

type State = {
    categories: Options[],
    currCategory: string,
};

const Dashboard: FunctionComponent = () => {
    const [state, setState] = useState({} as State);
    const { animatedRoutesEnd } = useContext(UserContext);
    const categories = useAppSelector(state => state.categories);
    const videos = useAppSelector(state => state.videos.videos || []);
    const onchangeCategory: ChangeEventHandler<HTMLSelectElement> = (e) => {
        const target = e.currentTarget;
        const value = target.value;
        setState(prevState => ({...prevState, currCategory: value}));
    };

    useEffect(() => {
        if (typeof categories.categories !== 'undefined') {
            if (categories.categories.length > 0) {
                const selectOptions = categories.categories.map(r => ({label: r.title_fr, value: r.id}));
                setState({ categories: selectOptions, currCategory: selectOptions[0].value })
            };
        };
        animatedRoutesEnd();
    }, [animatedRoutesEnd, categories]);

    return (
        <>
            {state.categories !== undefined ? 
            <>
                <h1 className="title1">Catégories et vidéos</h1>
                <span className="largeBorder"></span>
                <div className="row">
                    <TransitionLink to="/admin/new_category" className="submitButton">Ajouter une catégorie</TransitionLink>
                    <TransitionLink to="/admin/new_video" className="submitButton">Ajouter une vidéo</TransitionLink>
                </div>
                <div className="form">
                    <Select inputId="category" inputLabel="Catégorie" inputName="category" options={state.categories || []} isRequired={false} onchange={onchangeCategory} />
                    <TransitionLink to={`/admin/update_category/${state.currCategory}`} className="submitButton">Modifier la catégorie</TransitionLink>
                </div>
                <VideoGallery categoryId={state.currCategory} videos={videos} isAdmin/>
            </>  : 
            <>
                <h1 className="title1">Vous n'avez actuellement aucune catégorie ni aucune vidéo</h1>
                <span className="largeBorder"></span>
                <div className="form">
                    <TransitionLink to="/admin/new_category" className="submitButton">Ajouter une catégorie</TransitionLink>
                </div>
            </>}
            
        </>
    )
};

export default Dashboard;