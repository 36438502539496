export const APIAppError = [
    {
        type: "unknonw",
        msg: "Une erreur est survenue"
    },
    {
        type: 'unauthorized',
        msg: "Vous n'êtes pas autorisé à effectuer cette opération"
    },
    {
        type: 'usernotfoundexception',
        msg: "Cet utilisateur n'existe pas"
    }
];

export type AppError = {
    type: string,
    msg: string
};

type GraphqlAPIError = {
    data: unknown,
    errors: {
        errorType: string,
        message: string
    }[]
};

type CognitoError = {
    name: string,
    code: string
};

class Utils {

    private isGraphqlAPIError = (error: unknown): error is GraphqlAPIError => {
        if (typeof error === 'object' && error !== null && 'errors' in error) {
            const errors = error.errors;
            if (Array.isArray(errors) && errors.length > 0) {
                const err: unknown = errors[0];
                if (typeof err === 'object' && err !== null && 'errorType' in err && 'message' in err) {
                    return typeof err.errorType === 'string' && typeof err.message === 'string';
                }
            }
        }
        return false;
    };

    private isCognitoAPIError = (error: unknown): error is CognitoError => {
        return typeof error === 'object' && error !== null && 'name' in error && 'code' in error;
    };

    checkAPIError = (error: unknown): AppError => {
        console.error('Request error: ', error);
        const defaultError = {
            type: APIAppError[0].type,
            msg: APIAppError[0].msg
        };
        if (typeof error === 'string') {
            return {
                type: APIAppError[0].type,
                msg: error
            };
        };
        if (this.isGraphqlAPIError(error)) {
            const errorType = error.errors[0].errorType.toLowerCase();
            return {
                type: errorType,
                msg: APIAppError.find(r => r.type === errorType)?.msg || error.errors[0].message
            }
        }
        if (this.isCognitoAPIError(error)) {
            const errorType = error.name.toLocaleLowerCase();
            const err = APIAppError.find(r => r.type === errorType) || defaultError;
            return err;
        }
        return defaultError;
    };

};

export default Utils;