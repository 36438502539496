import { FocusEventHandler, FunctionComponent, useState } from "react";

type Props = {
    inputId: string,
    inputName: string,
    isRequired: boolean,
    isVideo?: boolean
};

type InputError = {
    isError: boolean,
    errorMsg: string,
}

type State = InputError & {file: File | null};

const MAX_LENGTH = 10000000000;

const FileUpload: FunctionComponent<Props> = ({inputId, inputName, isRequired, isVideo = false}) => {
    const [state, setState] = useState({} as State);

    const checkVideo = (value: File | null): InputError => {
        if (value) {
            const type = value.type;
            if (!(type.split("/")[0] === "video")) {
                return {isError: true, errorMsg: `Le fichier doit être de type vidéo`};
            };
            const size = value.size;
            if (size > MAX_LENGTH) {
                return {isError: true, errorMsg: `Le fichier dépasse la limite autorisée (${MAX_LENGTH}MB)`};
            };
            return {} as InputError;
        };
        if (isRequired) {
            return {isError: true, errorMsg: "La vidéo est obligatoire"};
        };
        return {} as InputError;
    };

    const checkImg = (value: File | null): InputError => {
        if (value) {
            const type = value.type;
            if (!(type.split("/")[0] === "image")) {
                return {isError: true, errorMsg: `Le fichier doit être de type image`};
            };
            const size = value.size;
            if (size > MAX_LENGTH) {
                return {isError: true, errorMsg: `Le fichier dépasse la limite autorisée (${MAX_LENGTH}MB)`};
            };
            return {} as InputError;
        };
        if (isRequired) {
            return {isError: true, errorMsg: "L'image est obligatoire"};
        };
        return {} as InputError;
    };

    const handleChange: FocusEventHandler<HTMLInputElement> = (e) => {
        const target = e.currentTarget;
        const file = target.files && target.files[0];
        const isInvalid = isVideo ? checkVideo(file) : checkImg(file);
        setState({...isInvalid, file});
    };

    return (
        <>
            <input 
                type="file"
                className={`d-none ${state.isError ? "invalid" : ""}`}
                id={inputId} name={inputName}
                accept={ isVideo ? "video/*" : "image/*" }
                onChange={handleChange}
            />
            <label htmlFor={inputId} className={`fileUpload ${state.isError ? "invalid" : ""}`}>
                {state.file ? 
                    isVideo ? <video src={URL.createObjectURL(state.file)} autoPlay muted /> : 
                    <img src={URL.createObjectURL(state.file)} alt="videoThumbnail" />
                :
                <>
                    <i className="pi pi-image mt-3 p-5" style={{'fontSize': '16px', borderRadius: '50%', color: "white"}}></i>
                    <p className="inputHelp center">Cliquer pour télécharger votre { isVideo ? "video" : "vignette" }</p>
                </>}
            </label>
            {state.isError && <p className="inputError">{state.errorMsg}</p>}
        </>
    );
};

export default FileUpload;