import { FormEventHandler, FunctionComponent, useContext, useEffect, useState } from "react";
import GlobalLoader from "../generic/GlobalLoader";
import InputTitle from "../generic/InputTitle";
import { UserContext } from "../layout/Layout";
import CategoriesApi from "../../api/CategoriesApi";
import useIsMounted from "../customHooks/useIsMounted";
import { useAppDispatch, useAppSelector } from "../customHooks/storeHookes";
import { setCategories } from "../../store/features/categories";
import { ROUTE_NAME } from "../routing/Routing";

type State = {
    loader?: boolean,
    isError?: boolean,
    errorMsg?: string,
};

const categoryApi = new CategoriesApi();

const NewCategory: FunctionComponent = () => {
    const [state, setState] = useState({} as State);
    const { animatedRoutesEnd, animatedRoutesBegin } = useContext(UserContext);
    const { isComponentMounted } = useIsMounted();
    const dispatch = useAppDispatch();
    const currCategories = useAppSelector(state => state.categories);

    const submit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        const target = e.currentTarget;
        const inputKeys = ["categoryName", "categoryNameEn"];
        for (let i = 0; i < inputKeys.length; i++) {
            const inputKey = inputKeys[i];
            if (target[inputKey].classList.contains("invalid")) {
                return;
            }; 
        };
        const title_fr = target.categoryName.value;
        const title_en = target.categoryNameEn.value || title_fr;
        const data = {
            title_fr,
            title_en,
        };
        const newState: State = {};
        try {
            setState({ loader: true });
            const category = await categoryApi.addCategory(data);
            const initialCategories = currCategories?.categories || [];
            dispatch(setCategories({ categories: [...initialCategories, category]}));
            animatedRoutesBegin(ROUTE_NAME.ADMIN);
        } catch (error) {
            const err = categoryApi.checkAPIError(error);
            newState.errorMsg = err.msg;
        } finally {
            if (isComponentMounted()) {
                setState({...newState, isError: true, loader: false});
            }
        };
    };

    useEffect(() => {
        animatedRoutesEnd();
    }, [animatedRoutesEnd]);

    return (
        <>
            {state.loader && <GlobalLoader />}
            <h1 className="title1">Ajouter une nouvelle catégorie :</h1>
            <span className="largeBorder"></span>
            <form className="form" onSubmit={submit}>
                <InputTitle inputLabel="Nom de la catégorie (français)" inputId="categoryName" inputName="categoryName" isRequired={true} inputPLaceholder="Nom de la catégorie (français)*" />
                <InputTitle inputLabel="Nom de la catégorie (anglais)" inputId="categoryNameEn" inputName="categoryNameEn" isRequired={false} inputPLaceholder="Nom de la catégorie (anglais)" />
                <button type="submit" className="submitButton">Valider</button>
                <p className={`alert ${state.isError ? "active" : ""}`}>{state.errorMsg || ""}</p>
            </form>
        </>
    );
};

export default NewCategory;