import { FunctionComponent, useContext, useEffect } from "react";
import { UserContext } from "../layout/Layout";
import useTranslate from "../translation/useTranslate";
import { texts } from "./text";

const About: FunctionComponent = () => {
    const { getCustomTranslation } = useTranslate();
    const { animatedRoutesEnd } = useContext(UserContext);

    const logos = [
        {src: 'logo adidas.png', alt: "adidas"},
        {src: 'logo airbnb.png', alt: "airbnb"},
        {src: 'logo asics.png', alt: "asics"},
        {src: 'logo BRED.png', alt: "bred"},
        {src: 'logo courir.png', alt: "courir"},
        {src: 'logo go sport.png', alt: "goSport"},
        {src: 'logo ugg.png', alt: "ugg"},
        {src: 'logo vans.png', alt: "vans"},
        {src: 'Nike-Logo.png', alt: "nike"},
    ];

    useEffect(() => {
        animatedRoutesEnd();
    }, [animatedRoutesEnd]);

    return (
        <>
            <div className="about">
                <h1 className="title1 black">La Niakerie</h1>
                <p className="alert active black">{getCustomTranslation({en: texts.subTitle.en, fr: texts.subTitle.fr})}</p>
                <span className="largeBorder black"></span>
                <div className="aboutContainer">
                    <img className="aboutImg" src="/img/about.jpg" alt="about" />
                    <p className="paragraph black">{getCustomTranslation({en: texts.p1.en, fr: texts.p1.fr})}</p>
                    <p className="paragraph black">{getCustomTranslation({en: texts.p2.en, fr: texts.p2.fr})}</p>
                    <p className="paragraph black">{getCustomTranslation({en: texts.p3.en, fr: texts.p3.fr})}</p>
                    <span className="largeBorder black"></span>
                    <div className="row align-start">
                        <div className="aboutItem">
                            <i className="pi pi-video"></i>
                            <h3 className="title3 black">{getCustomTranslation({en: texts.makerItemVideo.title.en, fr: texts.makerItemVideo.title.fr})}</h3>
                            {texts.makerItemVideo.body.map((r, i) => (
                                <p key={i + r.en} className="list">{getCustomTranslation({en: r.en, fr: r.fr})}</p>
                            ))}
                        </div>
                        <div className="aboutItem">
                            <i className="pi pi-camera"></i>
                            <h3 className="title3 black">{getCustomTranslation({en: texts.makerItemPhoto.title.en, fr: texts.makerItemPhoto.title.fr})}</h3>
                            {texts.makerItemPhoto.body.map((r, i) => (
                                <p key={i + r.en} className="list">{getCustomTranslation({en: r.en, fr: r.fr})}</p>
                            ))}
                        </div>
                        <div className="aboutItem">
                            <i className="pi pi-microphone"></i>
                            <h3 className="title3 black">{getCustomTranslation({en: texts.makerItemMusic.title.en, fr: texts.makerItemMusic.title.fr})}</h3>
                            {texts.makerItemMusic.body.map((r, i) => (
                                <p key={i + r.en} className="list">{getCustomTranslation({en: r.en, fr: r.fr})}</p>
                            ))}
                        </div>
                        <div className="aboutItem">
                            <i className="pi pi-user"></i>
                            <h3 className="title3 black">{getCustomTranslation({en: texts.makerItemModels.title.en, fr: texts.makerItemModels.title.fr})}</h3>
                            {texts.makerItemModels.body.map((r, i) => (
                                <p key={i + r.en} className="list">{getCustomTranslation({en: r.en, fr: r.fr})}</p>
                            ))}
                        </div>
                    </div>
                    <span className="largeBorder black"></span>
                    <h2 className="title2 black">{getCustomTranslation({en: texts.title2.en, fr: texts.title2.fr})}</h2>
                    <div className="row">
                        {logos.map(r => (
                            <img key={r.src} className="partnerImg" src={"/img/logos/" + r.src} alt={r.alt} />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default About;