import { FunctionComponent, MouseEventHandler, useContext, useEffect, useState } from "react";
import { UserContext } from "../layout/Layout";
import TransitionLink from "../transition/TransitionLink";
import useTranslate from "../translation/useTranslate";
import { Videos } from "../../graphql/API";

type Props = {
    categoryId: string,
    videos: Videos[]
    isAdmin?: boolean,
};

type State = {
    videosByCategory: Videos[]
};

const VideoGallery: FunctionComponent<Props> = ({categoryId, isAdmin = false, videos}) => {
    const [state, setState] = useState<State>({ videosByCategory: [] });
    const { getTranslation, getCustomTranslation } = useTranslate();
    const { animatedRoutesEnd } = useContext(UserContext);

    const playVideo: MouseEventHandler<HTMLVideoElement> = (e) => {
        const target = e.currentTarget;
        target.play()
            .then(r => r)
            .catch(e => e)
    };

    const stopVideo: MouseEventHandler<HTMLVideoElement> = (e) => {
        const target = e.currentTarget;
        target.pause();
    };

    useEffect(() => {
        let videosByCategory = videos.filter(r => r.categoriesID === categoryId);
        if (!isAdmin) {
            videosByCategory = videos.filter(r => r.categoriesID === categoryId && !r.isHome);
        }
        setState({
            videosByCategory
        });
        animatedRoutesEnd();
    }, [animatedRoutesEnd, videos, categoryId, isAdmin]);

    return (
        <>
            <div className="row">
                {state.videosByCategory.length > 0 ? state.videosByCategory.map((r, i) => (
                    <div className="videoBox" key={r.id + i}>
                        <img src={r.thumbnailLink || ''} alt={r.title_fr} />
                        <video src={r.videoLink || ''} onMouseOver={playVideo} onMouseLeave={stopVideo} />
                        <div className="videoBoxTitle">
                            <h1>{r.title_fr}</h1>
                            <div className="row end">
                                <TransitionLink to={`${getCustomTranslation({en: "/en/", fr: "/"})}video/${r.id}`} className="pi pi-play"></TransitionLink>
                                {isAdmin && <>
                                    <TransitionLink to={`/admin/delete_video/${r.id}`} className="pi pi-trash"></TransitionLink>
                                    <TransitionLink to={`/admin/update_video/${r.id}`} className="pi pi-eye"></TransitionLink>
                                </>}
                            </div>
                        </div>
                    </div>
                )) : 
                    isAdmin ? <TransitionLink to={`/admin/delete_category/${categoryId}`} className="submitButton">Supprimer la catégorie</TransitionLink> :
                    <p className="paragraph">{getTranslation("noVideo").label}</p>
                }
            </div>

        </>
    );
};

export default VideoGallery;