import { FunctionComponent, useCallback, useContext, useEffect, useState } from "react";
import { Routes, Route, Outlet } from 'react-router-dom';
import About from "../about/About";
import DeleteCategory from "../category/DeleteCategory";
import NewCategory from "../category/NewCategory";
import UpdateCategory from "../category/UpdateCategory";
import Contact from "../contact/Contact";
import Dashboard from "../dashboard/Dashboard";
import Home from "../home/Home";
import LegalNotice from "../legalNotice/LegalNotice";
import Portfolios from "../portfolios/Portfolios";
import ForgotPassword from "../profile/ForgotPassword";
import UpdatePassword from "../profile/UpdatePassword";
import Signin from "../signin/Signin";
import DeleteVideo from "../videos/DeleteVideo";
import NewVideo from "../videos/NewVideo";
import UpdateVideo from "../videos/UpdateVideo";
import VideoPlay from "../videos/VideoPlay";
import { useAppDispatch, useAppSelector } from "../customHooks/storeHookes";
import ForgotPasswordSubmit from "../profile/ForgotPasswordSubmit";
import UserApi from "../../api/UserApi";
import { setUser } from "../../store/features/user";
import { UserContext } from "../layout/Layout";

export enum ROUTE_NAME {
    HOME = '/',
    HOME_EN = '/en',
    LOGIN = '/login',
    FORGOT_PASSWORD = '/forgot_password',
    FORGOT_PASSWORD_SUBMIT = '/forgot_password_submit',
    VIDEO = '/video/:id',
    VIDEO_EN = '/en/video/:id',
    PORTFOLIOS = '/portfolios/:id',
    PORTFOLIOS_EN = '/en/portfolios/:id',
    ABOUT = '/about',
    ABOUT_EN = '/en/about',
    CONTACT = '/contact',
    CONTACT_EN = '/en/contact',
    LEGAL_NOTICE = '/legal_notice/',
    LEGAL_NOTICE_EN = '/en/legal_notice/',
    ADMIN = '/admin/',
    NEW_CATEGORY = '/admin/new_category',
    NEW_VIDEO = '/admin/new_video',
    DELETE_CATEGORY = '/admin/delete_category/:id',
    DELETE_VIDEO = '/admin/delete_video/:id',
    UPDATE_VIDEO = '/admin/update_video/:id',
    UPDATE_CATEGORY = '/admin/update_category/:id',
    UPDATE_PASSWORD = '/admin/update_password/:id',
    // PROFILE = '/admin/user',
};

const userApi = new UserApi();

const Routing: FunctionComponent = () => {

    return (
        <>
            <Routes>
                <Route path={ROUTE_NAME.HOME} element={<Home />} />
                <Route path={ROUTE_NAME.HOME_EN} element={<Home />} />
                <Route path={ROUTE_NAME.LOGIN} element={<Signin />} />
                <Route path={ROUTE_NAME.FORGOT_PASSWORD} element={<ForgotPassword />} />
                <Route path={ROUTE_NAME.FORGOT_PASSWORD_SUBMIT} element={<ForgotPasswordSubmit />} />
                <Route path={ROUTE_NAME.VIDEO} element={<VideoPlay />} />
                <Route path={ROUTE_NAME.VIDEO_EN} element={<VideoPlay />} />
                <Route path={ROUTE_NAME.PORTFOLIOS} element={<Portfolios />} />
                <Route path={ROUTE_NAME.PORTFOLIOS_EN} element={<Portfolios />} />
                <Route path={ROUTE_NAME.ABOUT} element={<About />} />
                <Route path={ROUTE_NAME.ABOUT_EN} element={<About />} />
                <Route path={ROUTE_NAME.CONTACT} element={<Contact />} />
                <Route path={ROUTE_NAME.CONTACT_EN} element={<Contact />} />
                <Route path={ROUTE_NAME.LEGAL_NOTICE} element={<LegalNotice />} />
                <Route path={ROUTE_NAME.LEGAL_NOTICE_EN} element={<LegalNotice />} />
                <Route path={ROUTE_NAME.ADMIN} element={<ProtectedRoutes />}>
                    <Route path={ROUTE_NAME.NEW_CATEGORY} element={<NewCategory />} />
                    <Route path={ROUTE_NAME.NEW_VIDEO} element={<NewVideo />} />
                    <Route path={ROUTE_NAME.DELETE_VIDEO} element={<DeleteVideo />} />
                    <Route path={ROUTE_NAME.DELETE_CATEGORY} element={<DeleteCategory />} />
                    <Route path={ROUTE_NAME.UPDATE_CATEGORY} element={<UpdateCategory />} />
                    <Route path={ROUTE_NAME.UPDATE_VIDEO} element={<UpdateVideo />} />
                    <Route path={ROUTE_NAME.UPDATE_PASSWORD} element={<UpdatePassword />} />
                    {/* <Route path={ROUTE_NAME.PROFILE} element={<Profile />} /> */}
                    <Route path={ROUTE_NAME.ADMIN} element={<Dashboard />} />
                </Route>
            </Routes>
        </>
    )
};

const ProtectedRoutes: FunctionComponent = () => {
    const [state, setState] = useState(false);
    const user = useAppSelector(state => state.user);
    const dispatch = useAppDispatch();
    const { animatedRoutesBegin } = useContext(UserContext);

    const getUser = useCallback(async () => {
        try {
            const getUserData = await userApi.getUser();
            dispatch(setUser({
                email: getUserData.attributes.email,
                username: getUserData.getUsername(),
                isAuth: true
            }));
        } catch (_) {
            animatedRoutesBegin(ROUTE_NAME.LOGIN);
        };
    }, [dispatch, animatedRoutesBegin]);

    useEffect(() => {
        if (user.isAuth === undefined) {
            getUser();
            return;
        };
        setState(user.isAuth);
    }, [getUser, user]);

    return (
        <>
            {state && <Outlet />}
        </>
    )
};

export default Routing;