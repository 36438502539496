import { FunctionComponent } from "react";

const GlobalLoader: FunctionComponent = () => {

    return (
        <>
            <div className="globalLoader">
                <i className="pi pi-spin pi-spinner"></i>
            </div>
        </>
    );
};

export default GlobalLoader;