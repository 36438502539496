/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNotifications = /* GraphQL */ `
  mutation CreateNotifications(
    $input: CreateNotificationsInput!
    $condition: ModelNotificationsConditionInput
  ) {
    createNotifications(input: $input, condition: $condition) {
      id
      sender
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateNotifications = /* GraphQL */ `
  mutation UpdateNotifications(
    $input: UpdateNotificationsInput!
    $condition: ModelNotificationsConditionInput
  ) {
    updateNotifications(input: $input, condition: $condition) {
      id
      sender
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteNotifications = /* GraphQL */ `
  mutation DeleteNotifications(
    $input: DeleteNotificationsInput!
    $condition: ModelNotificationsConditionInput
  ) {
    deleteNotifications(input: $input, condition: $condition) {
      id
      sender
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createVideos = /* GraphQL */ `
  mutation CreateVideos(
    $input: CreateVideosInput!
    $condition: ModelVideosConditionInput
  ) {
    createVideos(input: $input, condition: $condition) {
      id
      title_fr
      title_en
      description_fr
      description_en
      isHome
      categoriesID
      thumbnail
      name
      videoLink
      thumbnailLink
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateVideos = /* GraphQL */ `
  mutation UpdateVideos(
    $input: UpdateVideosInput!
    $condition: ModelVideosConditionInput
  ) {
    updateVideos(input: $input, condition: $condition) {
      id
      title_fr
      title_en
      description_fr
      description_en
      isHome
      categoriesID
      thumbnail
      name
      videoLink
      thumbnailLink
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteVideos = /* GraphQL */ `
  mutation DeleteVideos(
    $input: DeleteVideosInput!
    $condition: ModelVideosConditionInput
  ) {
    deleteVideos(input: $input, condition: $condition) {
      id
      title_fr
      title_en
      description_fr
      description_en
      isHome
      categoriesID
      thumbnail
      name
      videoLink
      thumbnailLink
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createCategories = /* GraphQL */ `
  mutation CreateCategories(
    $input: CreateCategoriesInput!
    $condition: ModelCategoriesConditionInput
  ) {
    createCategories(input: $input, condition: $condition) {
      id
      title_fr
      title_en
      Videos {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateCategories = /* GraphQL */ `
  mutation UpdateCategories(
    $input: UpdateCategoriesInput!
    $condition: ModelCategoriesConditionInput
  ) {
    updateCategories(input: $input, condition: $condition) {
      id
      title_fr
      title_en
      Videos {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteCategories = /* GraphQL */ `
  mutation DeleteCategories(
    $input: DeleteCategoriesInput!
    $condition: ModelCategoriesConditionInput
  ) {
    deleteCategories(input: $input, condition: $condition) {
      id
      title_fr
      title_en
      Videos {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
