import { FormEventHandler, FunctionComponent, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import InputTitle from "../generic/InputTitle";
import { UserContext } from "../layout/Layout";
import { Categories } from "../../graphql/API";
import useIsMounted from "../customHooks/useIsMounted";
import { useAppDispatch, useAppSelector } from "../customHooks/storeHookes";
import CategoriesApi from "../../api/CategoriesApi";
import { setCategories } from "../../store/features/categories";

type State = {
    category?: Categories,
    errorMsg?: string,
};

const categoryApi = new CategoriesApi();

const UpdateCategory: FunctionComponent = () => {
    const [state, setState] = useState({} as State);
    const params = useParams();
    const categoryId = params.id;
    const { isComponentMounted } = useIsMounted();
    const { animatedRoutesEnd, animatedRoutesBegin } = useContext(UserContext);
    const categories = useAppSelector(state => state.categories);
    const dispatch = useAppDispatch();

    const submit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        if (categoryId === undefined) {
            return;
        };
        const target = e.currentTarget;
        const inputKeys = ["categoryName", "categoryNameEn"];
        for (let i = 0; i < inputKeys.length; i++) {
            const inputKey = inputKeys[i];
            if (target[inputKey].classList.contains("invalid")) {
                return;
            }; 
        };
        const title_fr = target.categoryName.value;
        const title_en = target.categoryNameEn.value || title_fr;
        if (title_fr === state.category?.title_fr && title_en === state.category?.title_en) {
            setState(prevState => ({...prevState, errorMsg: "Aucune modification n'a été détéctée!"}));
            return;
        };
        const data = {
            id: categoryId,
            title_fr,
            title_en,
            _version: state.category?._version
        };
        try {
            target.updateCategorySubmit.disabled = true;
            const updatedCategory = await categoryApi.updateCategory(data);
            if (isComponentMounted()) {
                const updatedCategories = categories.categories !== undefined ? categories.categories.map(r => r.id === updatedCategory.id ? updatedCategory : r) : [ updatedCategory ];
                dispatch(setCategories({ categories: updatedCategories }));
                animatedRoutesBegin("/admin");
            };
        } catch (error) {
            target.updateCategorySubmit.disabled = false;
            const err = categoryApi.checkAPIError(error);
            if (isComponentMounted()) {
                setState({...state, errorMsg: err.msg});
            }
        };
    };

    useEffect(() => {
        if (typeof categoryId === 'string' && categories.categories !== undefined) {
            const category = categories.categories.find(r => r.id === categoryId);
            setState({ category });
        };
        animatedRoutesEnd();
    }, [categoryId, categories, animatedRoutesEnd]);

    return (
        <>
            {state.category && <>
                <h1 className="title1">Modifier la catégorie {state.category.title_fr} :</h1>
                <span className="largeBorder"></span>
                <form className="form" onSubmit={submit}>
                    <InputTitle inputLabel="Nom de la catégorie (français)" defaultvalue={state.category.title_fr} inputId="categoryName" inputName="categoryName" isRequired={true} inputPLaceholder="Nom de la catégorie (français)*" />
                    <InputTitle inputLabel="Nom de la catégorie (anglais)" defaultvalue={state.category.title_en || ''} inputId="categoryNameEn" inputName="categoryNameEn" isRequired={false} inputPLaceholder="Nom de la catégorie (anglais)" />
                    <p className={`alert ${state.errorMsg ? "active" : ""}`}>{state.errorMsg || ""}</p>
                    <button id="updateCategorySubmit" type="submit" className="submitButton">
                        <i className="pi pi-spin pi-spinner"></i>
                        Valider les modifications
                    </button>
                </form>
            </>}
        </>
    );
};

export default UpdateCategory;