import { configureStore } from '@reduxjs/toolkit';
import userReducer from './features/user';
import videosReducer from './features/videos';
import categoriesReducer from './features/categories';

export const store = configureStore({
  reducer: {
    user: userReducer,
    categories: categoriesReducer,
    videos: videosReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
  }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;