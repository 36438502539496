import { FunctionComponent, MouseEventHandler, useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { UserContext } from "../layout/Layout";

type Props = {
    to: string,
    className: string,
    children?: React.ReactNode,
};

const TransitionLink: FunctionComponent<Props> = ({to, className, children}) => {
    const location = useLocation();
    const { animatedRoutesBegin } = useContext(UserContext);

    
    const click: MouseEventHandler<HTMLAnchorElement> = (e) => {
        e.preventDefault();
        if (location.pathname !== to) {
            animatedRoutesBegin(to);
        };
    };

    return (
        <>
            <NavLink
                onClick={click}
                to={to}
                className={className}
            >
                {children || ""}
            </NavLink>
        </>
    );
};

export default TransitionLink;