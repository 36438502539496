import { createSlice } from '@reduxjs/toolkit';

export type UserStoreState = {
    email?: string,
    username?: string
    isAuth?: boolean
}

const initialState: UserStoreState = {};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
      setUser: (state, action) => {
        state.email = action.payload.email;
        state.username = action.payload.username;
        state.isAuth = action.payload.isAuth;
      }
    },
})

export const { setUser } = userSlice.actions;

export default userSlice.reducer;