import { FunctionComponent } from "react";
import TransitionLink from "../transition/TransitionLink";
import useTranslate from "../translation/useTranslate";

const Footer: FunctionComponent = () => {
    const { getTranslation } = useTranslate();

    return (
        <>
            <div className="footer">
                <div className="row nowrap start">
                    <span className="smallBorder"></span>
                    <a href="https://instagram.com/laniakerie?utm_medium=copy_link" rel="noreferrer" target="_blank" className="navLink icomoon">i</a>
                    <TransitionLink to={getTranslation("language").link || ""} className="navLink">{getTranslation("language").label}</TransitionLink>
                </div>
                <TransitionLink to={getTranslation("legalNotice").link || ""} className="navLink">&#169; La niakerie 2021</TransitionLink>
            </div>
            <p className="footerEgiance">Site réalisé par <a href="https://egiance.com" target="_blank" rel="noreferrer">Egiance.com</a></p>
        </>
    );
};

export default Footer;