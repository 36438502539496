import { createSlice } from '@reduxjs/toolkit';
import { Categories } from '../../graphql/API';

export type CategoriesStoreState = {
    categories?: Categories[]
};

const initialState: CategoriesStoreState = {};

export const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
      setCategories: (state, action) => {
        state.categories = action.payload.categories;
      },
      resetCategories: (state) => {
        state = {};
      }
    },
})

export const { setCategories, resetCategories } = categoriesSlice.actions;

export default categoriesSlice.reducer;