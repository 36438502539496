import { useLocation } from "react-router-dom";
import locales from "./locales";

type Values = {
    fr: string,
    en: string,
};

const useTranslate = () => {
    const location = useLocation();

    const getTranslation = (key: keyof typeof locales): {link?: string, label?: string} => {
        const pathname = location.pathname;
        const locale = /^\/en/.test(pathname) ? "en" : "fr";
        return locales[key][locale] || "";
    };

    const getCustomTranslation = (values: Values): string => {
        const pathname = location.pathname;
        const locale = /^\/en/.test(pathname) ? "en" : "fr";
        return locale === "en" ? values.en : values.fr;
    };

    return (
        { getTranslation, getCustomTranslation }
    );
};

export default useTranslate;