import { FunctionComponent, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { LOCAL_STORAGE_KEYS } from "../../utils/data";
import './cookieConsent.scss';

type State = {
    isActive: boolean
};

const CookieConsent: FunctionComponent = () => {
    const [state, setState] = useState<State>({ isActive: false });

    const closeCookieConsent = () => {
        setState(prevState => ({ ...prevState, isActive: false }));
        localStorage.setItem(LOCAL_STORAGE_KEYS.HAS_ACCEPTED_COOKIE, "true");
    };

    useEffect(() => {
        const hasAccepted = localStorage.getItem(LOCAL_STORAGE_KEYS.HAS_ACCEPTED_COOKIE);
        if (hasAccepted !== "true") {
            setState(prevState => ({ ...prevState, isActive: true }));
        };
    }, []);

    return (
        <>
            <div className={`cookieConsent ${state.isActive ? "active" : ""}`}>
                <div className="cookieConsentOverlay" onClick={closeCookieConsent}></div>
                <div className="cookieConsentContent">
                    <button type="button" onClick={closeCookieConsent}>
                        <FontAwesomeIcon icon={faClose} />
                    </button>
                    <p>En naviguant sur ce site, vous acceptez l&apos;utilisation de cookies pour vous offrir une meilleure expérience utilisateur et analyser notre trafic. Pour en savoir plus, consultez notre Politique de Confidentialité.</p>
                </div>
            </div>
        </>
    )
};

export default CookieConsent;