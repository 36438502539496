export const texts = {
    title1: {
        fr: "Données personnelles",
        en: "Privacy policy",
    },
    p1: {
        fr: "Laniakerie s'engage à protéger la vie privée de ses utilisateurs. Lorsque vous visitez notre site, nous collectons certaines informations automatiquement, telles que votre adresse IP, votre type de navigateur, et les pages que vous consultez. Nous collectons également les informations que vous nous fournissez volontairement, comme votre nom, adresse e-mail, et autres informations de contact lorsque vous remplissez un formulaire. Les informations que nous collectons sont utilisées pour fournir, exploiter et améliorer nos services. Communiquer avec vous, répondre à vos demandes et vous fournir des informations sur nos services. Personnaliser votre expérience sur notre site. Analyser l'utilisation de notre site et en améliorer le contenu. Nous ne partageons vos informations personnelles avec des tiers que dans les circonstances suivantes : avec votre consentement, pour respecter une obligation légale, pour protéger et défendre nos droits et intérêts. Nous mettons en œuvre des mesures de sécurité pour protéger vos informations personnelles contre tout accès non autorisé, modification, divulgation ou destruction. Conformément à la loi applicable, vous disposez des droits suivants concernant vos informations personnelles : droit d'accès et de rectification, droit à l'effacement, droit à la limitation du traitement, droit à la portabilité des données, droit d'opposition. Nous utilisons des cookies pour améliorer votre expérience sur notre site. Un cookie est un petit fichier texte stocké sur votre appareil. Vous pouvez choisir de désactiver les cookies via les paramètres de votre navigateur. Nous pouvons mettre à jour cette politique de confidentialité de temps en temps. Nous vous encourageons à consulter cette page régulièrement pour rester informé de nos pratiques de confidentialité.",
        en: "Laniakerie is committed to protecting the privacy of its users. When you visit our site, we automatically collect certain information, such as your IP address, browser type, and the pages you view. We also collect the information you voluntarily provide to us, such as your name, email address, and other contact information when you fill out a form. The information we collect is used to provide, operate, and improve our services. Communicate with you, respond to your requests, and provide you with information about our services. Personalize your experience on our site. Analyze the use of our site and improve its content. We only share your personal information with third parties under the following circumstances: with your consent, to comply with a legal obligation, to protect and defend our rights and interests. We implement security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. In accordance with applicable law, you have the following rights regarding your personal information: right of access and rectification, right to erasure, right to restrict processing, right to data portability, right to object. We use cookies to enhance your experience on our site. A cookie is a small text file stored on your device. You can choose to disable cookies through your browser settings. We may update this privacy policy from time to time. We encourage you to review this page regularly to stay informed about our privacy practices.",
    },
    title2: {
        fr: "Propriété intellectuelle",
        en: "Copyright",
    },
    p2: {
        fr: "L’ensemble de ce site relève des législations françaises et internationales sur le droit d’auteur et la propriété intellectuelle. Tous les droits de reproduction sont réservés, y compris pour les documents iconographiques, photographiques et vidéos. Toute reproduction totale ou partielle de ce site par quelque procédé que ce soit constituerait une contrefaçon sanctionnée par les articles L 335-2 et suivants du Code de la propriété intellectuelle.",
        en: "This website is protected by French and international legislation on royalties and intellectual property. All rights reserved, including for icons, images and videos files. We own or hold the rights to all the elements that make up this Website, in particular (but not limited to) the data, designs, graphics, photographs, soundtracks and videos. These elements may not be reproduced, displayed, distributed or transmitted to third parties by any means whatsoever, whether in whole or in part, without our express prior consent",
    },
    title3: {
        fr: "Site réalisé par",
        en: "Website realized by",
    },
};