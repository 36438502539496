import { FunctionComponent } from "react";

type Props = {
    side: string,
};

const BackRow: FunctionComponent<Props> = ({side}) => {

    return (
        <>
            <div className={`backrow ${side}`}></div>
        </>
    );
};

export default BackRow;