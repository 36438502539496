import { FocusEventHandler, FunctionComponent, useEffect, useState } from "react";
import useTranslate from "../translation/useTranslate";

type Props = {
    inputId: string,
    inputName: string,
};

type State = {
    random: number,
    isError: boolean,
    errorMsg: string,
};

const mandatoryMsg = {
    en: "Mandatory input",
    fr: "Ce champ est obligatoire",
};

const randomErr = {
    en: "Security response incorrect",
    fr: "La réponse à la question de sécurité est incorrecte",
};

const SALT = 5;

const InputRandom: FunctionComponent<Props> = ({inputId, inputName}) => {
    const [state, setState] = useState({} as State);
    const { getCustomTranslation } = useTranslate();

    const checkValue = (value: string): string | null => {
        if (value.length > 0) {
            const totalRandom = state.random + SALT;
            const isCorrectRandom = totalRandom.toString() === value;
            if (!isCorrectRandom) {
                return getCustomTranslation(randomErr);
            };
        };
        if (value.length === 0) {
            return getCustomTranslation(mandatoryMsg);
        };
        return null;
    };

    const blur: FocusEventHandler<HTMLInputElement> = (e) => {
        const target = e.currentTarget;
        const value = target.value;
        const isInvalid = checkValue(value);
        if (isInvalid) {
            setState(prevState => ({...prevState, isError: true, errorMsg: isInvalid}));
            return;
        };
        setState(prevState => ({ ...prevState, isError: false }));
    };

    useEffect(() => {
        const random = Math.round(Math.random() * 100);
        setState(prevState => ({...prevState, random}));
    }, []);

    return (
        <>
            <input
                type="text"
                id={inputId}
                name={inputName}
                placeholder={`${state.random} + ${SALT}*`}
                className={`input ${state.isError ? "invalid" : ""}`}
                required
                onBlur={blur}
            />
            {state.isError && <p className="inputError">{state.errorMsg}</p>}
        </>
    );
};

export default InputRandom;