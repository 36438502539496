export const texts = {
    subTitle: {
        fr: "Agence de production audiovisuelle au service des images de marque.",
        en: "Audiovisual production agency serving brand images."
    },
    p1: {
        fr: "Créé en 2014, nous avons eu de cesse de mettre un point d'honneur sur la créativité.",
        en: "Founded in 2014, we've had a constant focus on creativity."
    },
    p2 : {
        fr: "L'identité visuelle est un domaine complexe et très intime qui fait partie intégrante du quotidien des marques, des agences de communication et des artistes.\nNous vous aidons à construire et à diffuser votre message en mettant en place une stratégie de communication visuelle adaptée à votre identité.\nChaque vidéo est personnalisée et répond à un processus créatif bien organisé. Nous nous occupons de la totalité du déroulement, de la conception à la remise de la version finale.",
        en: "Visual identity is a complex and very intimate field that is an integral part of the daily life of brands, communication agencies and artists.\nWe help you build and spread your message by implementing a visual communication strategy adapted to your identity.\nEach video is personalized and follows a well-organized creative process. We take care of the entire process, from conception to delivery of the final version."
    },
    p3: {
        fr: "Notre maison se compose de plusieurs pôles de compétences, de la vidéo principalement, mais aussi de la photographie, de la conception design, de la création musicale et de la stratégie marketing, nous permettant de centraliser en notre seing l'ensemble des besoins en communication qu'une structure nécessite.",
        en: "Our company is composed of several poles of skills, mainly video, but also photography, design, music creation and marketing strategy, allowing us to centralize all the needs in communication that a structure requires."
    },
    title2: {
        fr: "Nos partenaires :",
        en: "Partners :"
    },
    makerItemVideo : {
        title: {
            fr: "Vidéo",
            en: "Video"
        },
        body : [
                {fr: "tournage", en: "shooting"},
                {fr: "montage", en: "montage"},
                {fr: "contenu sur mesure", en: "tailor-made"},
                {fr: "mise en format", en: "custom format"},
        
        ],
    },
    makerItemPhoto: {
        title: {
            fr: "Photographie",
            en: "Photography"
        },
        body: [
            {fr: "shooting lifestyle", en: "shooting lifestyle"},
            {fr: "shooting produit", en: "shooting product"},
            {fr: "formats personnalisés", en: "custom format"},
        ],
    },
    makerItemMusic: {
        title: {
            fr: "Son",
            en: "Sounds"
        },
        body: [
            {fr: "Production", en: "production"},
            {fr: "Originale et personnalisée", en: "creative"},
            {fr: "spots publicitaires", en: "adverts"},
        ],
    },
    makerItemModels: {
        title: {
            fr: "Models",
            en: "Models"
        },
        body: [
            {fr: "réseau privé", en: "private network"},
            {fr: "Influenceurs", en: "influencers"},
            {fr: "modèles", en: "models"},
            {fr: "sponsoring", en: "sponsoring"},
        ],
    }
};