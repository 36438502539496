import { Auth } from "aws-amplify";
import { CognitoUser } from '@aws-amplify/auth'
import Utils from "../utils/Utils";

type AppCognitoUser = CognitoUser & { attributes: { email: string } };

class UserApi extends Utils {

    getUser = async (): Promise<AppCognitoUser> => {
        const user = await Auth.currentAuthenticatedUser();
        return user;
    };

    signin = async (username: string, password: string): Promise<AppCognitoUser> => {
        let user = await Auth.signIn(username, password);
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            user = await Auth.completeNewPassword(user, password)
        };
        return user;
    };

    signout = async (): Promise<void> => {
        await Auth.signOut({ global: true });
    };

    forgotPassword = async (username: string): Promise<void> => {
        await Auth.forgotPassword(username);
    };

    forgotPasswordSubmit = async (username: string, code: string, newPassword: string): Promise<void> => {
        await Auth.forgotPasswordSubmit(username, code, newPassword);
    };

    changePassword = async (oldPassword: string, newPassword: string): Promise<void> => {
        const user = await this.getUser();
        await Auth.changePassword(user, oldPassword, newPassword);
    };

};

export default UserApi;