import { API } from "aws-amplify";
import Utils from "../utils/Utils";
import { GraphQLQuery } from "@aws-amplify/api";
import { CreateNotificationsInput, CreateNotificationsMutation, Notifications } from "../graphql/API";
import { createNotifications } from "../graphql/mutations";

class NotificationApi extends Utils {

    addNotifications = async (notification: CreateNotificationsInput): Promise<Notifications> => {
        const data = await API.graphql<GraphQLQuery<CreateNotificationsMutation>>({ query: createNotifications, variables: { input: notification }});
        return data.data?.createNotifications as Notifications;
    };

};

export default NotificationApi;