import { FunctionComponent } from "react";

type Props = {
    id: string,
    label: string,
};

const InputCheckbox: FunctionComponent<Props> = ({ id, label }) => {

    return (
        <>
            <div className="row nowrap start mb-1">
                <input type="checkbox" id={id} name={id} />
                <label htmlFor={id} className="inputHelp">{label}</label>
            </div>
        </>
    )
};

export default InputCheckbox;