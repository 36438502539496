import { FormEventHandler, FunctionComponent, useContext, useEffect } from "react";
import InputPassword from "../generic/InputPassword";
import SubmitButton from "../generic/SubmitButton";
import { UserContext } from "../layout/Layout";
import UserApi from "../../api/UserApi";
import useIsMounted from "../customHooks/useIsMounted";

const userApi = new UserApi();

const UpdatePassword: FunctionComponent = () => {
    const { animatedRoutesEnd } = useContext(UserContext);
    const { isComponentMounted } = useIsMounted();

    const isValidForm = (target: EventTarget & HTMLFormElement): boolean => {
        const inputKeys = ["expassword", "newpassword", "confirmpassword"];
        for (let i = 0; i < inputKeys.length; i++) {
            const inputKey = inputKeys[i];
            if (target[inputKey].classList.contains("invalid")) {
                return false;
            }; 
        };
        return true;
    };

    const submit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        const target = e.currentTarget;
        if (isValidForm(target)) {
            const userAlertMsg = target.userAlertMsg;
            const newpassword = target.newpassword.value;
            const confirmpassword = target.confirmpassword.value;
            if (newpassword !== confirmpassword) {
                userAlertMsg.value = "Vos nouveaux mots de passes ne correspondent pas!";
                userAlertMsg.classList.add("active");
                return;
            };
            const submitButton = target.passwordSubmitButton;
            userAlertMsg.classList.remove("active");
            submitButton.disabled = true;
            const data = {
                expassword: target.expassword.value,
                newpassword: target.newpassword.value,
            };
            try {
                await userApi.changePassword(data.expassword, data.newpassword);
                if (isComponentMounted()) {
                    submitButton.disabled = false;
                    submitButton.classList.remove("submitButton");
                    submitButton.classList.add("d-none");
                    submitButton.type = "button";
                    userAlertMsg.value = "Vos informations ont bien été modifiées";
                    userAlertMsg.classList.add("active");
                };
            } catch (error) {
                const errorMsg = userApi.checkAPIError(error);
                if (errorMsg && isComponentMounted()) {
                    submitButton.disabled = false;
                    userAlertMsg.value = errorMsg.msg;
                    userAlertMsg.classList.add("active");
                };
            };
        };
    };

    const enabledForm: FormEventHandler<HTMLFormElement> = (e) => {
        const target = e.currentTarget;
        const submitButton = target.passwordSubmitButton;
        if (submitButton.classList.contains("d-none")) {
            submitButton.classList.remove("d-none");
            submitButton.classList.add("submitButton");
            submitButton.type = "submit";
        };
    };

    useEffect(() => {
        animatedRoutesEnd();
    }, [animatedRoutesEnd]);
    
    return (
        <>
            <h1 className="title1">Modifier son mot de passe</h1>
            <span className="largeBorder"></span>
            <form className="form" onSubmit={submit} onChange={enabledForm}>
                <InputPassword inputLabel="Ancien mot de passe" inputId="expassword" inputName="expassword" isRequired={true} inputPLaceholder="Ancien mot de passe*" />
                <InputPassword inputLabel="Nouveau mot de passe" inputId="newpassword" inputName="newpassword" isRequired={true} inputPLaceholder="Nouveau mot de passe*" />
                <InputPassword inputLabel="Confirmer mot de passe" inputId="confirmpassword" inputName="confirmpassword" isRequired={true} inputPLaceholder="Confirmer mot de passe*" />
                <input type="text" name="userAlertMsg" className="alert" />
                <SubmitButton buttonId="passwordSubmitButton" buttonLabel="Valider les modifications" classname="d-none" />
            </form>
        </>
    );
};

export default UpdatePassword;