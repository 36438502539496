import { FunctionComponent, useContext, useEffect } from "react";
import { UserContext } from "../layout/Layout";
import useTranslate from "../translation/useTranslate";
import { texts } from "./texts";

const LegalNotice: FunctionComponent = () => {
    const { getCustomTranslation } = useTranslate();
    const { animatedRoutesEnd } = useContext(UserContext);

    useEffect(() => {
        animatedRoutesEnd();
    }, [animatedRoutesEnd]);
    
    return (
        <> 
            <div className="about">
                <h1 className="title1 black">La Niakerie</h1>
                <span className="largeBorder black"></span>
                <h3 className="title3 black">{getCustomTranslation({en: texts.title2.en, fr: texts.title2.fr})}</h3>
                <div className="aboutContainer">
                    <span className="largeBorder black"></span>
                    <p className="paragraph black">{getCustomTranslation({en: texts.p2.en, fr: texts.p2.fr})}</p>
                    <h3 className="title3 black">{getCustomTranslation({en: texts.title3.en, fr: texts.title3.fr})}</h3>
                    <span className="largeBorder black"></span>
                    <p className="paragraph black"><a href="https://egiance.com" target="_blank" rel="noreferrer">Egiance.com</a> - contact@egiance.com - Tél: (+33) 06 17 18 58 78</p>
                    <h3 className="title3 black">{getCustomTranslation({en: texts.title1.en, fr: texts.title1.fr})}</h3>
                    <span className="largeBorder black"></span>
                    <p className="paragraph black">{getCustomTranslation({en: texts.p1.en, fr: texts.p1.fr})}</p>
                </div>
            </div>
        </>
    );
};

export default LegalNotice;