import { FocusEventHandler, FunctionComponent, useState } from "react";

type Props = {
    inputId: string,
    inputName: string,
    inputPLaceholder: string,
    inputLabel: string,
    isRequired?: boolean,
    defaultvalue?: string,
};

type State = {
    isError: boolean,
    errorMsg: string,
};

const MAX_LENGTH = 50;

const InputTitle: FunctionComponent<Props> = ({inputId, inputName, inputPLaceholder, isRequired = false, defaultvalue, inputLabel}) => {
    const [state, setState] = useState({} as State);

    const checkValue = (value: string): State => {
        if (value.length > 0) {
            if (value.length > MAX_LENGTH) {
                return {isError: true, errorMsg: `Le titre ne peut pas excéder ${MAX_LENGTH} caractères`};
            };
            return {} as State;
        };
        if (isRequired) {
            return {isError: true, errorMsg: "Le titre est obligatoire"};
        };
        return {} as State;
    };

    const blur: FocusEventHandler<HTMLInputElement> = (e) => {
        const target = e.currentTarget;
        const value = target.value;
        const isInvalid = checkValue(value);
        setState(isInvalid);
    };


    return (
        <>
            <label htmlFor={inputId} className="inputHelp">{inputLabel} :</label>
            <input
                type="text"
                id={inputId}
                name={inputName}
                placeholder={inputPLaceholder}
                className={`input ${state.isError ? "invalid" : ""}`}
                required={isRequired || false}
                onBlur={blur}
                defaultValue={defaultvalue || ""}
            />
            {state.isError && <p className="inputError">{state.errorMsg}</p>}
        </>
    );
};

export default InputTitle;