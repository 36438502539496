import { FormEventHandler, FunctionComponent, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SubmitButton from "../generic/SubmitButton";
import { UserContext } from "../layout/Layout";
import TransitionLink from "../transition/TransitionLink";
import { useAppDispatch, useAppSelector } from "../customHooks/storeHookes";
import UserApi from "../../api/UserApi";
import useIsMounted from "../customHooks/useIsMounted";
import { setUser } from "../../store/features/user";
import { ROUTE_NAME } from "../routing/Routing";

const SALT = 5;

type State = {
    random: number;
    alert?: string,
};

const userApi = new UserApi();

const Signin: FunctionComponent = () => {
    const [state, setState] = useState({} as State);
    const { animatedRoutesEnd } = useContext(UserContext);
    const navigate = useNavigate();
    const user = useAppSelector(state => state.user);
    const dispatch = useAppDispatch();
    const { isComponentMounted } = useIsMounted();

    const resetState = () => {
        setState({random: state.random});
    };

    const setError = (msg: string) => {
        setState(prevState => ({...prevState, alert: msg}));
    };

    const checkForm = (email: string, password: string, random: string): string | null => {
        if (!email) {
            return "Veuillez saisir un email valide!";
        };
        if (!password) {
            return 'Veuillez saisir un mot de passe valide!';
        };
        if (!random) {
            return "Veuillez répondre à la question de sécurité!";
        };
        const totalRandom = state.random + SALT;
        const isCorrectRandom = totalRandom.toString() === random
        if (!isCorrectRandom) {
            return "La question de sécurité est incorrecte!";
        };
        return null;
    };

    const submit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        resetState();
        const target = e.currentTarget;
        const email = target.email.value;
        const password = target.password.value;
        const random = target.random.value;
        const isFormWrong = checkForm(email, password, random);
        if (isFormWrong) {
            setError(isFormWrong);
        } else {
            const data = {
                email,
                password,
            };
            try {
                target.signinSubmitButton.disabled = true;
                const user = await userApi.signin(data.email, data.password);
                if (isComponentMounted()) {
                    dispatch(setUser({
                        email: email,
                        username: user.getUsername(),
                        isAuth: true
                    }));
                    toDashboard();
                };
            } catch (error) {
                if (isComponentMounted()) {
                    const err = userApi.checkAPIError(error);
                    setError(err.msg);
                    target.signinSubmitButton.disabled = false
                };
            };
        };
    };

    const toDashboard = useCallback(() => {
        navigate(ROUTE_NAME.ADMIN);
    }, [navigate]);

    const getUser = useCallback(async () => {
        try {
            const user = await userApi.getUser();
            dispatch(setUser({
                email: user.attributes.email,
                username: user.getUsername(),
                isAuth: true
            }));
        } catch (_) {
            const random = Math.round(Math.random() * 100);
            setState({random});
            animatedRoutesEnd();
        }
    }, [animatedRoutesEnd, dispatch]);

    useEffect(() => {
        if (user.isAuth) {
            toDashboard();
            return;
        }
        getUser();
    }, [user, getUser, toDashboard]);

    return (
        <>
            <div>
                <h1 className="title1">Se connecter</h1>
                <span className="largeBorder"></span>
            </div>
            <form onSubmit={submit} className="form">
                <input type="text" id="email" placeholder="Email*" className="input" required />
                <input type="password" id="password" placeholder="Mot de passe*" className="input" required />
                <input type="text" id="random" placeholder={`${state.random} + ${SALT}`} className="input" required />
                <TransitionLink to="/forgot_password" className="inputHelp link">Mot de passe oubllié?</TransitionLink>
                <p className={`alert ${state.alert ? "active" : ""}`}>{state.alert || ""}</p>
                <SubmitButton type="submit" classname="submitButton" buttonId="signinSubmitButton" buttonLabel="Se connecter" />
            </form>
        </>
    )
};

export default Signin;