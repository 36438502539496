import { FunctionComponent } from "react";

type Props = {
    buttonId: string,
    buttonLabel: string,
    classname: string,
    type?: "button" | "submit" | "reset" | undefined,
};

const SubmitButton: FunctionComponent<Props> = ({buttonId, buttonLabel, classname, type}) => {

    return (
        <>
            <button id={buttonId} type={type || "button"} className={classname || ""}>
                <i className="pi pi-spin pi-spinner"></i>
                {buttonLabel || ""}
            </button>
        </>
    );
};

export default SubmitButton;