import { FunctionComponent } from "react";
import TransitionLink from "../transition/TransitionLink";
import useTranslate from "../translation/useTranslate";
import SubCategory from "./SubCategory";
import UserApi from "../../api/UserApi";
import { useAppDispatch, useAppSelector } from "../customHooks/storeHookes";
import { setUser } from '../../store/features/user';

const userApi = new UserApi();

const Nav: FunctionComponent = () => {
    const { getTranslation } = useTranslate();
    const user = useAppSelector(state => state.user);
    const dispatch = useAppDispatch();

    const signOut = async () => {
        await userApi.signout();
        dispatch(setUser({}));
    };

    return (
        <>
            <nav className="nav">
                <div>
                    <TransitionLink to={getTranslation("logo").link || "/"}  className="logo">
                        <img src="/img/logo.png" alt="logo la niakerie" />
                    </TransitionLink>
                </div>
                <div className="row nowrap w-auto">
                    {user.email && <>
                        <TransitionLink to="/admin" className="navLink">Admin</TransitionLink>
                        <button className="navLink" onClick={signOut}>Se déconnecter</button>
                    </>}
                    <SubCategory />
                    <TransitionLink to={getTranslation("about").link || "/"} className="navLink">{getTranslation("about").label}</TransitionLink>
                    <TransitionLink to={getTranslation("contact").link || "/"} className="navLink">{getTranslation("contact").label}</TransitionLink>
                </div>
            </nav>
        </>
    )
};

export default Nav;