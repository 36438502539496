import { FunctionComponent, MouseEventHandler, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../layout/Layout";
import TransitionLink from "../transition/TransitionLink";
import useIsMounted from "../customHooks/useIsMounted";
import VideosApi from "../../api/VideosApi";
import { useAppDispatch, useAppSelector } from "../customHooks/storeHookes";
import { ROUTE_NAME } from "../routing/Routing";
import { setVideos } from "../../store/features/videos";
import { Storage } from "aws-amplify";

type State = {
    errorMsg?: string,
};

const videosApi = new VideosApi();

const DeleteVideo: FunctionComponent = () => {
    const [state, setState] = useState({} as State);
    const params = useParams();
    const videoId = params.id;
    const { isComponentMounted } = useIsMounted();
    const { animatedRoutesEnd, animatedRoutesBegin } = useContext(UserContext);
    const videos = useAppSelector(state => state.videos.videos || []);
    const dispatch = useAppDispatch();

    const deleteVideo: MouseEventHandler<HTMLButtonElement> = async (e) => {
        const target = e.currentTarget;
        const video = videos.find(r => r.id === videoId);
        if (typeof video === 'undefined') {
            animatedRoutesBegin(ROUTE_NAME.ADMIN);
            return;
        }
        target.disabled = true;
        try {
            await Storage.remove(video.name, { level: 'public' });
            await Storage.remove(video.thumbnail, { level: 'public' });
            const data = {
                id: video.id,
                _version: video._version
            };
            await videosApi.deleteVideo(data);
            const videoFilter = videos.filter(r => r.id !== videoId);
            dispatch(setVideos({ videos: videoFilter }));
            if (isComponentMounted()) {
                animatedRoutesBegin(ROUTE_NAME.ADMIN);
            };
        } catch (error) {
            const errorMsg = videosApi.checkAPIError(error);
            if (isComponentMounted()) {
                target.disabled = false;
                setState({errorMsg: errorMsg.msg});
            };
        };
    };

    useEffect(() => {
        animatedRoutesEnd();
    }, [animatedRoutesEnd]);

    return (
        <>
            <div className="modal">
                <div className="modalBox">
                    <div className="modalBoxHeader">
                        <h1>Attention</h1>
                        <TransitionLink to="/admin" className="pi pi-times"></TransitionLink>
                    </div>
                    <div className="form">
                        <p className="alert active black">Êtes-vous certains de vouloir supprimer cette vidéo?</p>
                        {state.errorMsg && <p className="inputError center">{state.errorMsg}</p>}
                        <div className="row end">
                            <button onClick={deleteVideo} className="submitButton black">
                                <i className="pi pi-spin pi-spinner"></i>
                                Confirmer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DeleteVideo;